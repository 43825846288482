import React from 'react';
import PropTypes from 'prop-types';
import Card from 'react-bootstrap/Card';
import Loading from '../common/Loading';
import Highcharts from 'highcharts/highstock';
import HC_exporting from 'highcharts/modules/exporting';
import HC_accessibility from 'highcharts/modules/accessibility';
import HighchartsReact from 'highcharts-react-official';

const metricList = [
  ['tesSUCCESS','Successful payments'],
  ['tecDST_TAG_NEEDED','Transactions with missing Destination Tags'],
  ['tecUNFUNDED_PAYMENT','Unfunded payments'],
  ['tecUNFUNDED_OFFER', 'Not enough TakerGets currency'],
  ['tecPATH_PARTIAL', 'Paths with not enough liquidity'],
  ['tecPATH_DRY','Insufficient liquidity'],
  ['tecINSUF_RESERVE_OFFER','Insufficient XRP for new Offers'],
  ['tecINSUFFICIENT_FUNDS', 'Not enough of the necessary asset'],
  ['tecINSUFFICIENT_RESERVE', 'Insufficient reserve'],
  ['tecFROZEN','Counterparty assets subject to Global Freeze'],
  ['tecDIR_FULL', 'Account owner directory full'],
  ['tecEXPIRED', 'Object expiration time already passed'],
  ['tecKILLED', 'OfferCreate transaction killed'],
  ['tecNO_ENTRY', 'Object does not exist'],
  ['tecNO_PERMISSION', 'Sender does not have permission to do the operation'],
  ['tecHAS_OBLIGATIONS', 'Account to be deleted owns objects that cannot be deleted'],
  ['tecNO_DST', 'Receiving end of the transaction does not exist'],
  ['tecNO_DST_INSUF_XRP', 'Transaction not sending enough XRP to create account'],
  ['tecNO_LINE_INSUF_RESERVE', 'Not enough XRP to create a new trust line'],
  ['tecNO_LINE_REDUNDANT', 'Trust line does not exist'],
  ['tecOBJECT_NOT_FOUND', 'Object does not exist in the ledger'],
  ['tecOFFER_TYPE_MISMATCH', 'Offer type mismatch'],
  ['tecINVARIANT_FAILED','Invariant check failures'],
];

const highchartsConfig = (data, keyName) => {
  return {
    // Chart settings
    chart: {
      scrollablePlotArea: { minWidth: 600 },
    },
    plotOptions: {
      series: {
        dataGrouping: {
          enabled: false
        }
      }
    },
    title: {
      text: keyName[1],
    },
    subtitle: {
      text: `(${keyName[0]})`,
    },
    rangeSelector: {
        selected: 4,
    },
    yAxis: {
      labels: {
        formatter: function() {
          if ( this.value >= 1000 * 1000 )
            return Highcharts.numberFormat( this.value/1000000, 1) + " M";
          if ( this.value >= 1000 )
            return Highcharts.numberFormat( this.value/1000, 1) + " K";
          else
            return Highcharts.numberFormat(this.value, 0);
        },
      },
    },

    // Responsive settings
    responsive: {
      rules: [{
        condition: {
          maxWidth: 500
        },
        chartOptions: {
          chart: {
            height: 300
          },
          subtitle: {
            text: null
          },
          navigator: {
            enabled: false
          }
        }
      }]
    },

    // Chart data
    series: [
    {
      name: keyName[0],
      type: 'areaspline',
      color: Highcharts.getOptions().colors[3],
      data: data,

      fillColor: {
        linearGradient: {
          x1: 0,
          y1: 0,
          x2: 0,
          y2: 1
        },
        stops: [
          [0, Highcharts.getOptions().colors[3]],
          [1, new Highcharts.Color(Highcharts.getOptions().colors[3]).setOpacity(0).get('rgba')]
        ]
        }    
    }],
  }
};

class MetricResult extends React.Component {
  constructor(props) {
    super(props);
    HC_exporting(Highcharts);
    HC_accessibility(Highcharts);
  }

  componentDidMount() {
  }

  getChartData(metric, name) {
    return metric.map((datapoint) => [new Date(datapoint.date).getTime(), (typeof datapoint.result === 'object') ? datapoint.result[name] : null]);
  }

  render() {
    const { metric } = this.props;

    if (metric && metric.length === 0) {
      return <Loading />
    }

    return (
      <div>
        <br/>
        {metricList.map((k,v) => {
          return (
            <Card className="shadow-sm" key={k}>
              <Card.Body>
                <HighchartsReact
                  highcharts={Highcharts}
                  constructorType={'stockChart'}
                  options={highchartsConfig(this.getChartData(metric, k[0]), k)}
                />
              </Card.Body>
            </Card>
          );
        })}
      </div>
    );
  }
}

MetricResult.propTypes = {
  metric: PropTypes.array.isRequired,
}

export default MetricResult;