import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { currencyName } from '../common/Helpers';

/*
* AssetTag to display Asset names hyperlink to its issuer.
* 
* Usage: 

Simple: 
<AssetTag currency="XRP" />

With Issuer:
<AssetTag
 	currency={currency}
 	issuer={issuer}
/>
*
*/
const AssetTag = (props) => {
	const {
		currency,
		issuer,
	} = props;

	/*
	* Transform hex currency code to human readable code
	*/
	let currencyWithIssuer = currencyName(currency);
	if (issuer && currency && currency !== "XRP") {
		currencyWithIssuer = <Link to={`/account/${issuer}`}>{currencyWithIssuer}</Link>
	}

	return (
		<span className="money">
            {currencyWithIssuer}
		</span>
	)
}

AssetTag.defaultProps = {
	currency: "XRP",
	issuer: null,
}

AssetTag.propTypes = {
	currency: PropTypes.string,
	issuer: PropTypes.string,
}

export default AssetTag;