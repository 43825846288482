import React from 'react';
import PropTypes from 'prop-types';
import Image from "react-bootstrap/Image";
import defaultIcon from "../../assets/images/xrpl-symbol-black.svg";

const AccountIcon = (props) => {
    const { 
        twitter,
        emailHash,
        rounded,
        roundedCircle,
        thumbnail,
        height,
        width,
        ttl,
    } = props;

    const getIconURL = () => {
        if (typeof twitter === 'string') {
            return `https://unavatar.io/x/${twitter}?ttl=${ttl}&fallback=https://xrpscan.com/${defaultIcon}`;
        }
        else if (typeof emailHash === 'string' && emailHash.length > 0) {
           return `https://www.gravatar.com/avatar/${emailHash.toLowerCase()}.png?d=blank&s=45`
        }
        else {
            return defaultIcon;
        }
    };
    
    const iconTag = <span className='mr-2'>
        <Image
            src={getIconURL()}
            rounded={rounded}
            roundedCircle={roundedCircle}
            thumbnail={thumbnail}
            height={height}
            width={width}
        />
    </span>
    return iconTag;
}

AccountIcon.defaultProps = {
    twitter: undefined,
    emailHash: undefined,
    rounded: false,
    roundedCircle: true,
    thumbnail: false,
    height: 18,
    width: 18,
    ttl: "24h",
}

AccountIcon.propTypes = {
    twitter: PropTypes.string,
    emailHash: PropTypes.string,
    rounded: PropTypes.bool,
    roundedCircle: PropTypes.bool,
    thumbnail: PropTypes.bool,
    height: PropTypes.number,
    width: PropTypes.number,
    ttl: PropTypes.string,
}

export default AccountIcon;