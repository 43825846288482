import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FormattedNumber } from 'react-intl';
import { LPTOKEN_PREFIX, NATIVE_CURRENCY } from '../common/Constants';
import { currencyName } from '../common/Helpers';
import XIcon from '../lib/XIcon';
import XBadge from '../lib/XBadge';

/*
* Tag to display AMM assets.
* 
* Usage: 

Simple: 
<AMMAssetTag>{Asset}</AMMAssetTag>

*/
const AMMAssetTag = (props) => {
	const { min, max } = props;
    const { currency, issuer, value } = props.children;

    if (currency === NATIVE_CURRENCY) {
        return currency
    }
    if (currency && issuer) {
        if (value) {
            return (
                <span className="money text-nowrap">
        			<FormattedNumber
		    		    value={value}
    			    	minimumFractionDigits={min}
	    			    maximumFractionDigits={max}
			        />
                    {currency.length === 40 && currency.startsWith('03') &&
                    <XBadge variant="primary" className="ml-1">{LPTOKEN_PREFIX}&nbsp;{currency.substring(0,4)}<XIcon icon="ellipsis" faStyle={'fas'} className={'ml-0 mr-0'}/>{currency.substring(currency.length - 4)}</XBadge>
                    }
		        </span>
            )
        } else {
            return (
                <Link to={`/account/${issuer}`}>{currencyName(currency)}</Link>
            )
        }
    } else {
        return ""
    }
}

AMMAssetTag.defaultProps = {
	min: 0,
	max: 6,
}

AMMAssetTag.propTypes = {
	min: PropTypes.number,
	max: PropTypes.number,
}

export default AMMAssetTag;