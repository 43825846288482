import React from 'react';
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import PropTypes from 'prop-types';
import XIcon from '../lib/XIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const EmptyState = (props) => {
  const { title } = props;
  const description = props.children;

  return (
    <Row>
      <Col xs={12} md={12}>
        <Card className="text-center">
          <Card.Body>
            <Card.Title>
              <XIcon icon='empty-set' />
              <span className="pl-2">{title}</span>
            </Card.Title>
            <Card.Text className="lead">
              {description}
            </Card.Text>
            <h1><FontAwesomeIcon icon="fa-duotone fa-ring" size="2xl" fade className='text-warning'/></h1>
            <blockquote className="blockquote text-muted">
              All that is gold does not glitter,<br/>
              Not all those who wander are lost;<br/>
              The old that is strong does not wither,<br/>
              Deep roots are not reached by the frost.<br/>
              <footer className="blockquote-footer"><cite title="Author">J.R.R. Tolkien</cite></footer>
            </blockquote>
            <Card.Title>
              <Link variant="info" to="/">Take me home &rarr;</Link>
            </Card.Title>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}


EmptyState.propTypes = {
  title: PropTypes.string,
}

export default EmptyState;