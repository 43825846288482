import React from 'react';
import PropTypes from 'prop-types';
import { currencyName, getPrice, hex2ascii, LedgerEntryType } from '../../common/Helpers';
import { FormattedDate, FormattedRelative } from 'react-intl';
import XBadge from '../../lib/XBadge';

const Oracle = (props) => {
	const { node } = props;
    if ( node?.LedgerEntryType === LedgerEntryType.Oracle ) {
        return (
            <>
                {node?.OracleDocumentID &&
                <tr>
                    <td>OracleDocumentID:</td>
                    <td>
                        <span>
                            {node.OracleDocumentID}
                        </span>
                    </td>
                </tr>
                }

                {node?.Provider &&
                <tr>
                    <td>Provider:</td>
                    <td>
                        <span>
                            {hex2ascii(node.Provider)}
                        </span>
                    </td>
                </tr>
                }

                {node?.AssetClass &&
                <tr>
                    <td>AssetClass:</td>
                    <td>
                        <span>
                            {hex2ascii(node.AssetClass)}
                        </span>
                    </td>
                </tr>
                }

                {node?.LastUpdateTime && node?.LastUpdateTime >= 0 &&
                <tr>
                    <td>LastUpdateTime:</td>
                    <td>
                        <span>
                            <FormattedDate
                                value={Number(node?.LastUpdateTime) * 1000}
                                year='numeric'
                                month='short'
                                day='2-digit'
                                hour='2-digit'
                                minute='2-digit'
                                second='2-digit'
                                timeZone='UTC'
                                timeZoneName='short'
                            />
                        </span>
                        <XBadge variant="secondary" className="ml-2 text-uppercase">
                            <FormattedRelative value={node?.LastUpdateTime * 1000} />
                        </XBadge>
                    </td>
                </tr>
                }

                { node?.PriceDataSeries && Array.isArray(node?.PriceDataSeries) && node?.PriceDataSeries.length > 0 &&
                <>
                <tr className='table-success'>
                    <td colSpan="2">
                        Price data series:
                    </td>
                </tr>
                {node?.PriceDataSeries.map((data, index) =>
                <tr key={index}>
                    <td>
                        <XBadge variant="info text-monospace">{currencyName(data?.PriceData?.BaseAsset)}/{currencyName(data?.PriceData?.QuoteAsset)}</XBadge>
                    </td>
                    <td>
                        <span className='text-monospace'>
                            {(data?.PriceData?.AssetPrice && data?.PriceData?.Scale) && 
                                <XBadge variant="secondary">{getPrice(data?.PriceData?.AssetPrice, data?.PriceData?.Scale)}</XBadge>
                            }
                            {!(data?.PriceData?.AssetPrice && data?.PriceData?.Scale) && 
                                <XBadge variant="warning">OUTDATED</XBadge>
                            }
                        </span>
                    </td>
                </tr>
                )}
                </>
                }
            </>
        );    
    } else {
        return <></>;
    }
}

Oracle.propTypes = {
	node: PropTypes.object,
}

export default Oracle;