import React from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import XIcon from '../lib/XIcon';
import XBadge from './XBadge';

const CardTitleContainer = (props) => {
  const { icon, title, tag, tagVariant } = props;

  return (
    <Row>
      <Col>
        <XIcon icon={icon} />
        <span className="ml-2">{title}</span>
        {typeof tag === "string" && tag.length > 0 &&
          <XBadge variant={tagVariant} className="ml-2">{tag}</XBadge>
        }
      </Col>
    </Row>
  );
}

CardTitleContainer.defaultProps = {
  tagVariant: "info",
}

CardTitleContainer.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
  tag: PropTypes.string,
  tagVariant: PropTypes.string,
};

export default CardTitleContainer;