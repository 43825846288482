import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ReactJson from 'react-json-view';
import LedgerTag from '../lib/LedgerTag';
import CardTitleContainer from '../lib/CardTitleContainer';
import TxHash from '../lib/TxHash';
import LedgerEntryTypeTag from '../lib/LedgerEntryTypeTag';
import EmptyState from '../lib/EmptyState';
import XBadge from '../lib/XBadge';
import CommonFields from './types/CommonFields';
import AccountRoot from './types/AccountRoot';
import RippleState from './types/RippleState';
import Ticket from './types/Ticket';
import Check from './types/Check';
import DepositPreauth from './types/DepositPreauth';
import SignerList from './types/SignerList';
import Amendments from './types/Amendments';
import DirectoryNode from './types/DirectoryNode';
import FeeSettings from './types/FeeSettings';
import LedgerHashes from './types/LedgerHashes';
import PayChannel from './types/PayChannel';
import Offer from './types/Offer';
import Escrow from './types/Escrow';
import NFTokenOffer from './types/NFTokenOffer';
import NFTokenPage from './types/NFTokenPage';
import AMM from './types/AMM';
import DID from './types/DID';
import Oracle from './types/Oracle';

const ObjectInfo = (props) => {
	const { ledgerObject, error } = props;
	const [ showJSON, setShowJSON ] = useState(false);

	const handleShowJSON = () => {
		setShowJSON(true);
	}

	const handleCloseJSON = () => {
		setShowJSON(false);
	}

	if (error) {
		return (
		<EmptyState title="Object not found">
			Object with this index was not found in the latest validated ledger.
		</EmptyState>
		);
	}

	return (
		<Card className="shadow-sm">
			<Card.Body>
				<Card.Title>
					<CardTitleContainer icon="object-union" title="Object summary" />
				</Card.Title>
				<Row>
					<Col xs={12} md={8}>
						<Table responsive>
							<thead>
							{ledgerObject?.node?.LedgerEntryType &&
								<tr>
									<th className="data-header text-nowrap">Ledger entry:</th>
									<th><LedgerEntryTypeTag type={ledgerObject.node.LedgerEntryType} /></th>
								</tr>
							}
							</thead>

							<tbody>
								<CommonFields node={ledgerObject.node} />
								<AccountRoot node={ledgerObject.node} />
								<Amendments node={ledgerObject.node} />
								<AMM node={ledgerObject.node} />
								<Check node={ledgerObject.node} />
								<DepositPreauth node={ledgerObject.node} />
								<DID node={ledgerObject.node} />
								<Oracle node={ledgerObject.node} />
								<DirectoryNode node={ledgerObject.node} />
								<Escrow node={ledgerObject.node} />
								<FeeSettings node={ledgerObject.node} />
								<LedgerHashes node={ledgerObject.node} />
								<NFTokenOffer node={ledgerObject.node} />
								<NFTokenPage node={ledgerObject.node} />
								<Offer node={ledgerObject.node} />
								<PayChannel node={ledgerObject.node} />
								<RippleState node={ledgerObject.node} />
								<SignerList node={ledgerObject.node} />
								<Ticket node={ledgerObject.node} />
							</tbody>
						</Table>
					</Col>

					<Col xs={12} md={4}>
						<Table responsive>
							<thead>
								<tr>
									<th colSpan="2">Properties</th>
								</tr>
							</thead>
							<tbody>
								{ledgerObject?.node?.PreviousTxnLgrSeq &&
								<tr>
									<td>Prev ledger:</td>
									<td><LedgerTag ledger_index={ledgerObject.node.PreviousTxnLgrSeq} /></td>
								</tr>
								}

								{ledgerObject?.node?.PreviousTxnID &&
								<tr>
									<td>Prev tx:</td>
									<td><TxHash hash={ledgerObject.node.PreviousTxnID} /></td>
								</tr>
								}

								{ledgerObject?.node?.Flags >= 0 &&
								<tr>
									<td>Flags:</td>
									<td>{ledgerObject.node.Flags}</td>
								</tr>
								}

								{ledgerObject?.node?.OwnerNode &&
								<tr>
									<td>OwnerNode:</td>
									<td>
										<span>
											{ledgerObject?.node.OwnerNode}
										</span>
									</td>
								</tr>
								}

								{ledgerObject?.validated &&
								<tr>
									<td>Validated:</td>
									<td><XBadge variant="success">TRUE</XBadge></td>
								</tr>
								}
							</tbody>
						</Table>
					</Col>
				</Row>
			</Card.Body>

			<Card.Footer>
				<ButtonToolbar>
					<Button variant="outline-info" size="sm" onClick={handleShowJSON}>Raw JSON</Button>
					<Modal
						show={showJSON}
						onHide={handleCloseJSON}
						size="lg"
						aria-labelledby="contained-modal-title-vcenter"
						centered
					>
						<Modal.Header closeButton>
							<Modal.Title>Raw Object</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<ReactJson
								src={ledgerObject}
								collapseStringsAfterLength={64}
								enableClipboard={false}
								indentWidth={4}
								displayDataTypes={false}
								displayArrayKey={true}
							/>
						</Modal.Body>
						<Modal.Footer>
							<Button variant="secondary" onClick={handleCloseJSON}>
								Close
							</Button>
						</Modal.Footer>
					</Modal>
				</ButtonToolbar>
			</Card.Footer>
		</Card>
	);
}

ObjectInfo.defaultProps = {
	error: false,
}

ObjectInfo.propTypes = {
	ledgerObject: PropTypes.object.isRequired,
	error: PropTypes.bool,
}

export default ObjectInfo;
