import React, { useState, useEffect }  from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import Money from '../lib/Money';
import CardTitleContainer from '../lib/CardTitleContainer';
import XBadge from '../lib/XBadge';
import AMMFactChart from './AMMFactChart';
import { XRPSCAN_API_URL } from '../../config';
import { handleResponse } from '../common/Helpers';
import { FormattedNumber, FormattedRelative } from 'react-intl';

const AMMFact = (props) => {
    const [ facts, setFacts ] = useState({});
	// const [ loadingFact, setLoadingFact ] = useState(false);
    const [ metrics, setMetrics ] = useState([]);
    // const [ loadingMetrics, setLoadingMetrics ] = useState(false);

    /*
    * Fetch global AMM facts
    */
    useEffect(() => {
        // setLoadingFact(true);
        fetch(`${XRPSCAN_API_URL}/fact/AMM`)
        .then(handleResponse)
        .then((data) => {
            setFacts(data);
        })
        .catch((error) => {
            setFacts({})
        })
        .finally(() => {
            // setLoadingFact(false);
        })
        return () => {
            setFacts({})
        }
    }, []);

    /*
    * Fetch AMM metrics
    */
    useEffect(() => {
        // setLoadingMetrics(true);
        fetch(`${XRPSCAN_API_URL}/metrics/amm`)
        .then(handleResponse)
        .then((data) => {
            setMetrics(data);
        })
        .catch((error) => {
            setMetrics([])
        })
        .finally(() => {
            // setLoadingMetrics(false);
        })
        return () => {
            setMetrics([])
        }
    }, []);


	return (
	<div>
		<Card className="shadow-sm">
			<Card.Body>
				<Card.Title>
					<CardTitleContainer icon="chart-pie-simple" title="AMM Overview" />
				</Card.Title>
				<Row>
					<Col xs={12} md={8}>
						<AMMFactChart metrics={metrics} />
					</Col>

					<Col xs={12} md={4}>
						<Table responsive>
							<thead>
								{facts.xrp_locked > 0 &&
								<tr>
									<th>XRP Pooled:</th>
									<th className="text-right">
										<XBadge variant="primary">
											<Money value={facts.xrp_locked} min={6} max={6}/>
										</XBadge>
									</th>
								</tr>
								}
							</thead>
							<tbody>
								{facts.asset_count > 0 &&
								<tr>
									<td>Total assets:</td>
									<td className="text-right">
                                        <div className='text-monospace'>
											<FormattedNumber value={facts.asset_count}/>
										</div>
									</td>
								</tr>
								}
								{facts.unique_tokens > 0 &&
								<tr>
									<td>Unique tokens:</td>
									<td className="text-right">
                                        <div className='text-monospace'>
											<FormattedNumber value={facts.unique_tokens}/>
										</div>
									</td>
								</tr>
								}
								{facts.xrp_pairs > 0 &&
								<tr>
									<td>XRP pairs:</td>
									<td className="text-right">
                                        <div className='text-monospace'>
											<FormattedNumber value={facts.xrp_pairs}/>
										</div>
									</td>
								</tr>
								}
								{facts.amm_count > 0 &&
								<tr>
									<td>Active pools:</td>
									<td className="text-right">
										<div className='text-monospace'>
											<FormattedNumber value={facts.amm_count}/>
										</div>
									</td>
								</tr>
								}
								{facts.updated_at > 0 &&
								<tr>
									<td colSpan={2}  className='text-muted text-right text-nowrap'>
										Last updated: <FormattedRelative value={new Date(facts.updated_at * 1000)} />
									</td>
								</tr>
								}
							</tbody>
						</Table>
					</Col>

				</Row>
			</Card.Body>
		</Card>
	</div>
	);
}

AMMFact.propTypes = {
	facts: PropTypes.object.isRequired,
	metrics: PropTypes.object.isRequired,
}

export default AMMFact;