import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Card from 'react-bootstrap/Card';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { LinkContainer } from 'react-router-bootstrap';
import { Helmet } from 'react-helmet';
import { XRPSCAN_API_URL } from '../../config';
import { handleResponse, decodeAddress } from '../common/Helpers';
import AccountInfo from './AccountInfo';
import AccountAMM from './AccountAMM';
import AccountNFToken from './AccountNFToken';
import AccountAsset from './AccountAsset';
import AccountObligation from './AccountObligation';
import AccountOrder from './AccountOrder';
import AccountEscrow from './AccountEscrow';
import AccountTrustline from './AccountTrustline';
import AccountSetting from './AccountSetting';
import AccountActivation from './AccountActivation';
import AccountTransaction from './AccountTransaction';
import AccountAnalysis from './AccountAnalysis';
import AccountAncestry from './AccountAncestry';
import Loading from '../common/Loading';
import IntegrationGuide from '../common/IntegrationGuide';
import SponsorContainer from '../sponsorship/SponsorContainer';
import XIcon from '../lib/XIcon';
import TextAd from '../sponsorship/TextAd';
import Analytics from '../common/Analytics';
import AccountObject from './AccountObject';


class Account extends React.Component {
	constructor(props) {
		super(props);

		this.handleSelect = this.handleSelect.bind(this);
		const address = decodeAddress(this.props.match.params.address);

		this.state = {
			key: 'payments',
			address: address,
			account: {},
			assets: [],
			obligations: [],
			orders: [],
			escrows: [],
			settings: {},
			loading: false,
			isAMM: false,
			error: null,
		}
	}

	// React view methods
	componentDidMount() {
		const { address } = this.state;
		this.fetchAccount(address);
		Analytics.pageview();
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.location.pathname !== nextProps.location.pathname) {
			const newAddress = decodeAddress(nextProps.match.params.address);
			this.setState({ address: newAddress });
			this.fetchAccount(newAddress);
			Analytics.pageview();
		}
	}

	handleSelect(key) {
		const { address } = this.state;
		switch (key) {
			case 'nftokens':
				break;
			case 'amm':
				break;
			case 'assets':
				this.fetchAssets(address);
				break;
			case 'obligations':
				this.fetchObligations(address);
				break;
			case 'orders':
				this.fetchOrders(address);
				break;
			case 'escrows':
				this.fetchEscrows(address);
				break;
			case 'trustlines':
				break;
			case 'settings':
				this.fetchSettings(address);
				break;
			case 'activations':
				break;
			case 'ancestry':
				break;
			case 'objects':
				break;
			case 'transactions':
				break;
			case 'analysis':
				break;
			default:
				break;
		}
		this.setState({key});
	}

	// Data methods
	fetchAccount(address) {
		this.setState({ loading: true })

		fetch(`${XRPSCAN_API_URL}/account/${address}`)
			.then(handleResponse)
			.then((data) => {
				// Remove this from production deployment
				if (data && typeof data.account === 'undefined' && typeof data.Account === 'string') {
					data.account = data.Account
				}

				this.setState({
					loading: false,
					account: data,
					error: null,
				});

				if (data && data.AMMID) {
					this.setState({ isAMM: true })
				}

				// If this account is unfunded/inactive, redirect user to account activation page.
				if (data?.error?.error === "actNotFound" && data?.parent === undefined) {
					this.props.history.push(`/activate/${address}`);
				}
			})
			.catch((error) => {
				this.setState({
					loading: false,
					error: error.errorMessage,
				});
			});
	}

	fetchAssets(address) {
		fetch(`${XRPSCAN_API_URL}/account/${address}/assets`)
			.then(handleResponse)
			.then((data) => { this.setState({ assets: data })	})
			.catch((error) => {});
	}
	fetchObligations(address) {
		fetch(`${XRPSCAN_API_URL}/account/${address}/obligations`)
			.then(handleResponse)
			.then((data) => { this.setState({ obligations: data })	})
			.catch((error) => {});
	}
	fetchBalances(address) {
		fetch(`${XRPSCAN_API_URL}/account/${address}/balances`)
			.then(handleResponse)
			.then((data) => { this.setState({ balances: data })	})
			.catch((error) => {});
	}
	fetchOrders(address) {
		fetch(`${XRPSCAN_API_URL}/account/${address}/orders`)
			.then(handleResponse)
			.then((data) => { this.setState({ orders: data })	})
			.catch((error) => {});
	}
	fetchEscrows(address) {
		fetch(`${XRPSCAN_API_URL}/account/${address}/escrows`)
			.then(handleResponse)
			.then((data) => { this.setState({ escrows: data })	})
			.catch((error) => {});
	}
	fetchSettings(address) {
		fetch(`${XRPSCAN_API_URL}/account/${address}/settings`)
			.then(handleResponse)
			.then((data) => { this.setState({ settings: data })	})
			.catch((error) => {});
	}

	render() {
		const { 
			address,
			account,
			assets,
			obligations,
			orders,
			escrows,
			settings,
			loading,
			isAMM,
			error,
		} = this.state;

		// render only loading component if loading state is set to true
		if (loading) {
			return <Loading />
		}

		return (
			<div>
				<Helmet>
					<title>{`${address}`} - XRP Account | XRPSCAN</title>
				</Helmet>
				<Row>
					<Col>
						<IntegrationGuide/>
					</Col>
				</Row>
				<Row>
					<Col xs={12} md={4}>
						<Breadcrumb>
							<LinkContainer to={`/`}><Breadcrumb.Item>Home</Breadcrumb.Item></LinkContainer>
							{account.account &&
							<Breadcrumb.Item active>{account.account}</Breadcrumb.Item>
							}
						</Breadcrumb>
					</Col>
					<Col xs={12} md={8}>
						<SponsorContainer />
					</Col>
				</Row>
				<Row>
					<Col xs={12} md={12}>
						<AccountInfo account={account} addressParam={this.props.match.params.address} error={error}/>
					</Col>
				</Row>
				<Row>
					<Col xs={12} md={12}>
						<TextAd/>
					</Col>
				</Row>
				<Row>
					<Col xs={12} md={12}>
						<Card>
							<Card.Body>
								<Card.Title>
									<XIcon icon='coins' />
									<span className="pl-2">Assets</span>
								</Card.Title>
								<Tabs
									activekey={this.state.key}
									defaultActiveKey={'transactions'}
									onSelect={this.handleSelect}
									transition={false}
									mountOnEnter={true}
									unmountOnExit={true}
									id="account-transactions"
									className="account-details-tab"
								>
									<Tab eventKey="transactions" title="Transactions">
										<AccountTransaction address={address}  activationHash={account.tx_hash} />
									</Tab>
									{isAMM &&
									<Tab eventKey="amm" title="AMM">
										<AccountAMM address={address} />
									</Tab>
									}
									<Tab eventKey="nftokens" title="NFTs">
										<AccountNFToken address={address} />
									</Tab>
									<Tab eventKey="assets" title="Tokens">
										<AccountAsset assets={assets}/>
									</Tab>
									<Tab eventKey="obligations" title="Issued">
										<AccountObligation obligations={obligations} account={account}/>
									</Tab>
									<Tab eventKey="trustlines" title="Trustlines">
										<AccountTrustline address={address} />
									</Tab>
									<Tab eventKey="orders" title="DEX Orders">
										<AccountOrder orders={orders} account={account} />
									</Tab>
									<Tab eventKey="escrows" title="Escrows">
										<AccountEscrow escrows={escrows} account={account} />
									</Tab>
									<Tab eventKey="settings" title="Settings">
										<AccountSetting settings={settings} />
									</Tab>
									<Tab eventKey="activations" title="Activations">
										<AccountActivation address={address} />
									</Tab>
									<Tab eventKey="ancestry" title="Ancestry">
										<AccountAncestry address={address} />
									</Tab>
									<Tab eventKey="objects" title="Objects">
										<AccountObject address={address} />
									</Tab>
									<Tab eventKey="analysis" title="Analyze">
										<AccountAnalysis address={address} account={account} />
									</Tab>
								</Tabs>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</div>
		)
	}
}

export default Account;