const TransactionResultDescription = {
  "tesSUCCESS": "The transaction was applied and broadcasted to other servers. If this appears in a validated ledger, then the transaction's success is final.",
  "tecCLAIM": "Unspecified failure, transaction cost destroyed.",
  "tecCRYPTOCONDITION_ERROR": "This Escrow transaction contained a malformed or mismatched crypto-condition.",
  "tecDIR_FULL": "The transaction tried to add an object (Trustline, Check, Escrow, or Payment Channel) to an account's owner directory, but that account cannot own any more objects in the ledger.",
  "tecDUPLICATE": "The transaction tried to create an object that already exists.",
  "tecDST_TAG_NEEDED": "The transaction did not specify a Destination tag, but the destination account has requireDestinationTag flag enabled and requires it.",
  "tecEXPIRED": "The transaction tried to create an object (such as an Offer or a Check) whose provided Expiration time has already passed.",
  "tecFAILED_PROCESSING": "An unspecified error occurred when processing the transaction.",
  "tecFROZEN": "The transaction failed because one or both of the assets involved are subject to a Global Freeze.",
  "tecHAS_OBLIGATIONS": "The AccountDelete transaction failed because the account to be deleted owns objects that cannot be deleted.",
  "tecINSUF_RESERVE_LINE": "The transaction failed because the sending account does not have enough XRP to create a new trust line.",
  "tecINSUF_RESERVE_OFFER": "The transaction failed because the sending account does not have enough XRP to create a new Offer.",
  "tecINSUFF_FEE": "The transaction failed because the sending account does not have enough XRP to pay the transaction cost that it specified.",
  "tecINSUFFICIENT_RESERVE": "The transaction would increase the reserve requirement higher than the sending account's balance.",
  "tecINTERNAL": "Unspecified internal error, with transaction cost applied.",
  "tecINVARIANT_FAILED": "An invariant check failed when trying to execute this transaction.",
  "tecNEED_MASTER_KEY": "This transaction tried to cause changes that require the Master key.",
  "tecNO_ALTERNATIVE_KEY": "The transaction tried to remove the only available method of authorizing transactions.",
  "tecNO_AUTH": "The transaction failed because it needs to add a balance on a trust line to an account with the RequireAuth flag enabled, and that trust line has not been authorized.",
  "tecNO_DST": "The account on the receiving end of the transaction does not exist.",
  "tecNO_DST_INSUF_XRP": "The account on the receiving end of the transaction does not exist, and the transaction is not sending enough XRP to activate it.",
  "tecNO_ISSUER": "The account specified in the issuer field of a currency amount does not exist.",
  "tecKILLED": "The OfferCreate transaction specified the FillOrKill flag and could not be filled, so it was killed.",
  "tecNO_LINE": "The TakerPays field in OfferCreate transaction specifies an asset whose issuer has RequireAuth enabled, and the account making the offer does not have a trust line for that asset.",
  "tecNO_LINE_INSUF_RESERVE": "The transaction failed because the sending account does not have enough XRP to create a new trust line.",
  "tecNO_LINE_REDUNDANT": "The transaction failed because it tried to set a trust line to its default state, but the trust line did not exist.",
  "tecNO_PERMISSION": "The sender does not have permission to do this operation.",
  "tecNO_REGULAR_KEY": "The AccountSet transaction tried to disable the master key, but the account does not have another way to authorize transactions.",
  "tecNO_TARGET": "The transaction referenced an Escrow or PayChannel ledger object that doesn't exist, either because it never existed or it has already been deleted. ",
  "tecOVERSIZE": "This transaction could not be processed, because the server created an excessively large amount of metadata when it tried to apply the transaction.",
  "tecOWNERS": "The transaction requires that account sending it has a nonzero owners count, so the transaction cannot succeed.",
  "tecPATH_DRY": "The transaction failed because the provided paths did not have enough liquidity to send anything at all. The source and destination accounts may not be linked by trust lines.",
  "tecPATH_PARTIAL": "The transaction failed because the provided paths did not have enough liquidity to send the full amount.",
  "tecTOO_SOON": "The AccountDelete transaction failed because the account was recently activated. The current ledger index must be at least 256 higher than the account's sequence number.",
  "tecUNFUNDED": "The transaction failed because the account does not hold enough XRP to pay the amount in the transaction and satisfy the additional reserve necessary to execute this transaction.",
  "tecUNFUNDED_PAYMENT": "The transaction failed because the sending account is trying to send more XRP than it holds, not counting the reserve.",
  "tecUNFUNDED_OFFER": "The transaction failed because the account creating the offer does not have any of the TakerGets currency.",
};

export default TransactionResultDescription;
