import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import * as Highcharts from 'highcharts';
import HC_itemseries from 'highcharts/modules/item-series';
import HC_exporting from 'highcharts/modules/exporting';
import HC_accessibility from 'highcharts/modules/accessibility';
import HighchartsReact from 'highcharts-react-official';
import { Card } from 'react-bootstrap';
require("highcharts/modules/item-series")(Highcharts)

const XPRL_IMPLEMENTATION_NAME = 'rippled';

const highchartsConfig = (serverVersions, nodeCount, nodeTotal) => {
  // const nodeThreshold = 0.8;
  return {
    // Chart settings
    chart: {
        type: 'item',
        height: 350,
    },
    title: {
        text: `${nodeTotal} Nodes`,
        align: "center",
    },
    subtitle: {
        text: 'running on rippled versions'
    },
    legend: {
        labelFormat: '{name} <span style="opacity: 0.4">{y}</span>'
    },

    // Data
    series: [{
        name: 'Nodes',
        keys: ['name', 'y', 'color', 'label'],
        data: [
            [`${XPRL_IMPLEMENTATION_NAME}-${serverVersions[0]}`, nodeCount[0], '#32A467', serverVersions[0]],
            [`${XPRL_IMPLEMENTATION_NAME}-${serverVersions[1]}`, nodeCount[1], '#4C90F0', serverVersions[1]],
            [`${XPRL_IMPLEMENTATION_NAME}-${serverVersions[2]}`, nodeCount[2], '#F0B726', serverVersions[2]],
            [`${XPRL_IMPLEMENTATION_NAME}-${serverVersions[3]}`, nodeCount[3], '#AC2F33', serverVersions[3]],
            [`${XPRL_IMPLEMENTATION_NAME}-${serverVersions[4]}`, nodeCount[4], '#800080', serverVersions[4]],
            [`${XPRL_IMPLEMENTATION_NAME}-${serverVersions[5]}`, nodeCount[5], '#008695', serverVersions[5]],
            [`${XPRL_IMPLEMENTATION_NAME}-${serverVersions[6]}`, nodeCount[6], '#969696', serverVersions[6]],
            [`${XPRL_IMPLEMENTATION_NAME}-${serverVersions[7]}`, nodeCount[7], '#000000', serverVersions[7]],
        ],
        dataLabels: {
            enabled: true,
            format: '{point.label}',
            style: {
                textOutline: '3px contrast'
            }
        },

        // Circular options
        center: ['50%', '88%'],
        size: '180%',
        startAngle: -100,
        endAngle: 100,
        selected: true,
    }],

    responsive: {
        rules: [{
            condition: {
                maxWidth: 600,
            },
            chartOptions: {
                series: [{
                    dataLabels: {
                        distance: -30
                    }
                }]
            }
        }]
    },
    }
}

// Convert serverVersions array to key-value with value initialized to 0
const arrayToKeyValue = (arr) => {
  return arr.reduce((a,v) => ({ ...a, [v]: 0}), {})
}

const getNodeCount = (serverVersions, nodes) => {
  const versions = arrayToKeyValue(serverVersions)
  for (const n of nodes) {
    if (n?.server_version in versions) {
      versions[n.server_version]++
    }
  }
  return Object.values(versions);
}

const NetworkParliament = (props) => {
  const {
    validators,
    nodes,
    serverVersions,
  } = props;

  const nodeCount = getNodeCount(serverVersions, nodes);

  useEffect(() => {
    HC_itemseries(Highcharts);
    HC_exporting(Highcharts);
    HC_accessibility(Highcharts);
  }, [validators]);

  return (
    <Card className='shadow-sm'>
      <Card.Body>
        <HighchartsReact
          highcharts={Highcharts}
          options={highchartsConfig(serverVersions, nodeCount, nodes.length)}
        />
      </Card.Body>
    </Card>
  );
}

NetworkParliament.propTypes = {
  nodes: PropTypes.array,
  serverVersions: PropTypes.array,
};

export default NetworkParliament;