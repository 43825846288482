import React, { useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { LinkContainer } from 'react-router-bootstrap';
import { Helmet } from 'react-helmet';
import { XRPSCAN_API_URL } from '../../config';
import { handleResponse } from '../common/Helpers';
import Analytics from '../common/Analytics';
import XIcon from '../lib/XIcon';
import IntegrationGuide from '../common/IntegrationGuide';
import SponsorContainer from '../sponsorship/SponsorContainer';
import TextAd from '../sponsorship/TextAd';
import SearchForm from './SearchForm';
import SearchResults from './SearchResults';

const AdvancedSearch = () => {
	const [ loading, setLoading ] = useState(false);
	const [ data, setData ] = useState({});
	const [ searchAccount, setSearchAccount ] = useState(null);
	const [ searchDestination, setSearchDestination ] = useState(null);
	const [ errorMessage, setErrorMessage ] = useState(null);

	useEffect (() => {
		Analytics.pageview();
	}, [])

	const sanitize = (searchParams) => {
		const sanitizedParams = {};
		const validParams = Object.keys(searchParams).filter(key => (
			typeof searchParams[key] === 'string' &&
			searchParams[key].length > 0
		));
		validParams.map(param => sanitizedParams[param] = searchParams[param] );
		return sanitizedParams;
	}

	const newSearch = (searchParams) => {
		setLoading(true);
		setData({});
		setErrorMessage(null);
		setSearchAccount(searchParams.Account);
		setSearchDestination(searchParams.Destination);
	}

	const getActiveAddress = () => {
		if (searchAccount && searchAccount.length > 0 && !searchDestination) {
			return searchAccount;
		}
		if (searchDestination && searchDestination.length > 0 && !searchAccount) {
			return searchDestination;
		}
	}

	const getQueryString = (searchParams) => {
    if (searchParams && Object.keys(searchParams).length > 0) {
      return Object.entries(searchParams).map(sp => sp.join('=')).join('&');
    }
    else {
      return '';
    }
  }

	const	handleSearch = (searchParams) => {
		newSearch(searchParams);
    const queryString = getQueryString(sanitize(searchParams));
		Analytics.event({category: 'AdvancedSearch', action: 'Advanced search', label: queryString});
    fetch(`${XRPSCAN_API_URL}/search/transactions?${queryString}`)
      .then(handleResponse)
      .then((data) => {
        setLoading(false);
        setData(data);
				if (data.errorMessage) {
					setErrorMessage(data.errorMessage);
				}
      })
      .catch((error) => {
        setLoading(false);
        setData({});
				if (error.errorMessage) {
					setErrorMessage(error.errorMessage);
				}
      });
  }

	return (
		<div>
		<Helmet>
			<title>Advanced search | XRPSCAN</title>
		</Helmet>
		<Row>
			<Col>
				<IntegrationGuide/>
			</Col>
		</Row>
		<Row>
			<Col xs={12} md={4}>
				<Breadcrumb>
					<LinkContainer to={`/`}><Breadcrumb.Item>Home</Breadcrumb.Item></LinkContainer>
					<Breadcrumb.Item active>Search</Breadcrumb.Item>
				</Breadcrumb>
			</Col>
			<Col xs={12} md={8}>
				<SponsorContainer />
			</Col>
		</Row>
		<Row>
			<Col xs={12} md={12}>
				<SearchForm handler={handleSearch} loading={loading}/>
			</Col>
		</Row>
		<Row>
			<Col xs={12} md={12}>
				<TextAd/>
			</Col>
		</Row>
		<Row>
			<Col xs={12} md={12}>
				<Card>
					<Card.Body>
						<Card.Title>
							<XIcon icon='file-invoice' />
							<span className="pl-2">Transactions</span>
						</Card.Title>
						<SearchResults data={data} loading={loading} address={getActiveAddress()} errorMessage={errorMessage}/>
					</Card.Body>
					{data && data.count >= 0 &&
						<Card.Footer>{data.count} transaction{data.count === 1 ? '' : 's'} found</Card.Footer>
					}
				</Card>
			</Col>
		</Row>
	</div>
	);
}

export default AdvancedSearch;