import React from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge';
import { Link } from 'react-router-dom';
import { FormattedNumber, FormattedDate } from 'react-intl';
import { getAmendmentVoteStyle, rippleTime2UnixTime } from '../common/Helpers';
import CardTitleContainer from '../lib/CardTitleContainer';


const AmendmentInfo = (props) => {
  const { amendment, error } = props;
  const fragment = amendment.name ? `#${amendment.name.toLowerCase()}` : '';

  if (error) {
    return <div className="error">{error}</div>
  }

  return (
  <div>
    <Card className="shadow-sm">
      <Card.Body>
        <Card.Title>
          <CardTitleContainer icon="cogs" title="Amendment summary" />
        </Card.Title>
        <Row>
          <Col xs={12} md={8}>
            <Table responsive>
              <thead>
                <tr>
                  <th>Name:</th>
                  <th>
                    <Link to={`/amendment/${amendment.name}`}>{amendment.name}</Link>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Amendment ID:</td>
                  <td className="hash text-muted">{amendment.amendment_id}</td>
                </tr>
                <tr>
                  <td>Introduced in:</td>
                  <td>
                    <span>
                      { amendment.introduced ? `v${amendment.introduced}` : 'TBD' }
                    </span>
                  </td>
                </tr>
                { amendment.tx_hash &&
                <tr>
                  <td>Enabled by:</td>
                  <td>
                    <span className="hash">
                      <Link to={`/tx/${amendment.tx_hash}`}>{amendment.tx_hash}</Link>
                    </span>
                  </td>
                </tr>
                }
                { amendment.threshold &&
                <tr>
                  <td>Threshold:</td>
                  <td><span className="hash">{amendment.threshold}/{amendment.validations}</span> votes</td>
                </tr>
                }
                <tr>
                  <td>Details:</td>
                  <td>
                    <a href={`https://xrpl.org/known-amendments.html${fragment}`} rel="nofollow noopener noreferrer" target="_blank">
                      https://xrpl.org/known-amendments.html{fragment}
                    </a>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>

          <Col xs={12} md={4}>
            <Table responsive>
              <thead>
                <tr>
                  <th>Status</th>
                  <th>
                    { amendment.enabled ?
                    <Badge variant="success">ENABLED</Badge> :
                    <Badge variant="danger">NOT ENABLED</Badge>
                    }
                    { amendment.deprecated &&
                    <Badge variant="dark" className="ml-1">DEPRECATED</Badge>
                    }
                  </th>
                </tr>
              </thead>
              <tbody>
                { amendment.count >= 0 &&
                <tr>
                  <td>Yeas:</td>
                  <td>
                    <span className="hash">{amendment.count}</span>
                  </td>
                </tr>
                }
                { amendment.count >= 0 &&
                <tr>
                  <td>Nays:</td>
                  <td>
                    <span className="hash">{amendment.validations - amendment.count}</span>
                  </td>
                </tr>
                }

                {!amendment.enabled_on &&
                <tr>
                  <td>ETA:</td>
                  <td>
                    {!amendment.majority &&
                    <span>
                      <Badge variant="warning">&nbsp;VOTING&nbsp;</Badge>&nbsp;
                    </span>
                    }
                    {amendment.majority &&
                    <span className="mark">
                      <FormattedDate
                        value={ rippleTime2UnixTime(amendment.majority) * 1000 + (14 * 24 * 3600 * 1000) }
                        year='numeric'
                        month='short'
                        day='2-digit'
                        hour='2-digit'
                        minute='2-digit'
                        second='2-digit'
                        timeZone='UTC'
                        timeZoneName='short'
                      />
                    </span>
                    }

                  </td>
                </tr>
                }

                { amendment.count >= 0 &&
                <tr
                  className={ amendment.enabled ? '' : getAmendmentVoteStyle(amendment.count, amendment.threshold) }
                >
                  <td>Consensus:</td>
                  <td>
                    <span className="hash"><FormattedNumber value={(amendment.count/amendment.validations) * 100} maximumFractionDigits={2} />%</span>
                  </td>
                </tr>
                }
                { amendment.enabled_on &&
                <tr className='table-success'>
                  <td>Enabled on:</td>
                  <td>
                    <span>
                      <FormattedDate
                        value={amendment.enabled_on}
                        year='numeric'
                        month='short'
                        day='2-digit'
                        hour='2-digit'
                        minute='2-digit'
                        second='2-digit'
                        timeZone='UTC'
                        timeZoneName='short'
                      />
                    </span>
                  </td>
                </tr>
                }
              </tbody>
            </Table>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  </div>
  );
}


AmendmentInfo.propTypes = {
  amendment: PropTypes.object.isRequired,
  error: PropTypes.object,
}

export default AmendmentInfo;
