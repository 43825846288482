import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import EmptyStateNoRecord from '../lib/EmptyStateNoRecord';
import Loading from '../common/Loading';
import { XRPSCAN_API_URL } from '../../config';
import { feeUnitToPercent, handleResponse } from '../common/Helpers';
import { Card, Col, Row, Table } from 'react-bootstrap';
import AccountTag from '../lib/AccountTag';
import XBadge from '../lib/XBadge';
import Money from '../lib/Money';


const AccountAMM = (props) => {
	const { address } = props;
    const [ ammInfo, setAmmInfo ] = useState({})
	const [ loading, setLoading ] = useState(false);

    const sortVoteSlots = (voteSlots) => {
        if (voteSlots && voteSlots.length > 0) {
            return voteSlots.sort((a,b) => a.vote_weight < b.vote_weight);
        } else {
            return voteSlots;
        }
    }

	/*
	* Fetch amm_info for this account
	*/
	useEffect(() => {
		setLoading(true);
		fetch(`${XRPSCAN_API_URL}/amm/${address}`)
		.then(handleResponse)
		.then((data) => {
            setAmmInfo(data);
		})
		.catch((error) => {
			setAmmInfo({});
		})
		.finally(() => {
			setLoading(false);
		})
		return () => {
			setAmmInfo({});
		}
	}, [address]);

	if (loading) {
		return <Loading />;
	}

	if (!loading && ammInfo && Object.keys(ammInfo).length === 0) {
		return (
			<EmptyStateNoRecord title="No AMM info">
				Information for this AMM instance is unavailable
			</EmptyStateNoRecord>
		)
	} else {
		return (
            <>
                {typeof ammInfo === 'object' && Object.keys(ammInfo).length > 0 &&
                <Card>
                    <Card.Body>
                        <Row>
        					<Col xs={12} md={12}>
                            <Table responsive>
                                <thead>
                                    {ammInfo.account &&
                                    <tr>
                                        <th className="data-header">AMM Account</th>
                                        <th>
                                            <AccountTag link>{ammInfo.account}</AccountTag>
                                        </th>
                                        <th></th>
                                    </tr>
                                    }
                                </thead>
                                <tbody>
                                    {ammInfo.amount &&
                                    <tr>
                                        <td>Asset 1:</td>
                                        <td>
                                            {typeof ammInfo.amount === 'string' &&
                                                <Money value={ammInfo.amount} drops/>
                                            }
                                            {typeof ammInfo.amount === 'object' &&
                                                <Money
                                                    currency={ammInfo.amount.currency}
                                                    issuer={ammInfo.amount.issuer}
                                                    value={ammInfo.amount.value}
                                                />
                                            }
                                        </td>
                                        <td>
                                            {ammInfo.asset_frozen &&
                                                <XBadge variant="secondary" className="text-monospace">FROZEN</XBadge>
                                            }
                                        </td>
                                    </tr>
                                    }

                                    {ammInfo.amount2 &&
                                    <tr>
                                        <td>Asset 2:</td>
                                        <td>
                                            {typeof ammInfo.amount2 === 'string' &&
                                                <Money value={ammInfo.amount2} drops />
                                            }
                                            {typeof ammInfo.amount2 === 'object' &&
                                                <Money
                                                    currency={ammInfo.amount2.currency}
                                                    issuer={ammInfo.amount2.issuer}
                                                    value={ammInfo.amount2.value}
                                                />
                                            }
                                        </td>
                                        <td>
                                            {ammInfo.asset2_frozen &&
                                                <XBadge variant="secondary" className="text-monospace">FROZEN</XBadge>
                                            }
                                        </td>
                                    </tr>
                                    }

                                    {ammInfo?.lp_token && Object.keys(ammInfo.lp_token).length > 0 &&
                                    <tr>
                                        <td>LP Token:</td>
                                        <td>
                                            <Money
                                                currency={ammInfo?.lp_token.currency}
                                                issuer={ammInfo?.lp_token.issuer}
                                                value={ammInfo?.lp_token.value}
                                            />
                                        </td>
                                        <td><XBadge variant="secondary" className="text-monospace">CODE: {ammInfo?.lp_token.currency}</XBadge></td>
                                    </tr>
                                    }

                                    {ammInfo.trading_fee >= 0 && 
                                    <tr>
                                        <td>Trading fee:</td>
                                        <td>
                                            {feeUnitToPercent(ammInfo.trading_fee)}%
                                        </td>
                                        <td></td>
                                    </tr>
                                    }

                                    {ammInfo?.auction_slot && Object.keys(ammInfo.auction_slot).length > 0 &&
                                        <tr>
                                            <td>Auction slot:</td>
                                            <td>
                                                <AccountTag link>{ammInfo.auction_slot.account}</AccountTag>
                                                <XBadge variant="primary" className="ml-2">FEE: {feeUnitToPercent(ammInfo.auction_slot.discounted_fee)}%</XBadge>
                                            </td>
                                            <td className='text-left'>
                                                <XBadge variant="secondary" className="text-monospace">Expires: {ammInfo.auction_slot.expiration} UTC</XBadge>
                                            </td>
                                        </tr>
                                    }
                                </tbody>
                            </Table>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                }

                {ammInfo?.vote_slots && ammInfo?.vote_slots.length > 0 &&
                <Card>
                    <Card.Body>
                        <Card.Title>Vote slots</Card.Title>
                        <Table responsive>
                        <thead>
                            <tr>
                                <th className="text-center">#</th>
                                <th className='text-left'>Account</th>
                                <th className='text-right'>Trading fee</th>
                                <th className='text-right'>Vote weight</th>
                            </tr>
                        </thead>
                        <tbody>
                            { sortVoteSlots(ammInfo.vote_slots).map((voteSlot, index) => 
                            <tr key={index}>
                                <td className="text-center">{index+1}</td>
                                <td className="text-left">
                                    <AccountTag link>{voteSlot.account}</AccountTag>
                                </td>
                                <td className="text-right">
                                    {feeUnitToPercent(voteSlot.trading_fee)}%
                                </td>
                                <td className="text-right text-monospace">
                                    <XBadge variant="primary">{voteSlot.vote_weight}</XBadge>
                                </td>
                            </tr>
                            )}
                        </tbody>
                    </Table>
                    </Card.Body>
                </Card>
                }
            </>
        );
	}
}

AccountAMM.propTypes = {
	address: PropTypes.string.isRequired,
}

export default AccountAMM;