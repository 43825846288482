import React from 'react';
import PropTypes from 'prop-types';
import Card from 'react-bootstrap/Card';
import Loading from '../common/Loading';
import Highcharts from 'highcharts/highstock';
import HC_exporting from 'highcharts/modules/exporting';
import HC_accessibility from 'highcharts/modules/accessibility';
import HighchartsReact from 'highcharts-react-official';
import XIcon from '../lib/XIcon';


const highchartsConfig = (data, validator) => {
  return {
    // Chart settings
    chart: {
      scrollablePlotArea: { minWidth: 600 },
    },
    title: {
      text: validator.domain || '',
    },
    subtitle: {
      text: validator.validation_public_key || '',
    },
    xAxis: {
      type: 'datetime',
    },
    rangeSelector: {
        selected: 5,
    },
    navigator: {
      enabled: false,
    },
    yAxis: {
      title: {
        text: 'Agreement (%)',
      },
      opposite:false,
      min: 0,
      max: 100,
      labels: {
        formatter: function() {
          return Highcharts.numberFormat(this.value, 0);
        },
      },
    },
    legend: {
      enabled: false,
    },

    // Responsive settings
    responsive: {
      rules: [{
        condition: {
          maxWidth: 500
        },
        chartOptions: {
          chart: {
            height: 400
          },
          subtitle: {
            text: null
          },
          navigator: {
            enabled: false
          }
        }
      }]
    },

    // Plot options
    plotOptions: {
      areaspline: {
        fillColor: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1
          },
          stops: [
            [0, Highcharts.getOptions().colors[0]],
            [1, new Highcharts.Color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
          ]
        },
        marker: {
          radius: 2
        },
        lineWidth: 1,
        states: {
          hover: {
            lineWidth: 1
          }
        },
        threshold: null
      }
    },

    // Chart data
    series: [
    {
      data: data,
      name: 'Agreement (%)',
      type: 'areaspline',
      color: Highcharts.getOptions().colors[0],
      fillColor: {
        linearGradient: {
          x1: 0,
          y1: 0,
          x2: 0,
          y2: 1
        },
        stops: [
          [0, Highcharts.getOptions().colors[0]],
          [1, new Highcharts.Color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
        ]
      },
    }],
  }
};

class ValidatorChart extends React.Component {
  constructor(props) {
    super(props);
    HC_exporting(Highcharts);
    HC_accessibility(Highcharts);
  }

  componentDidMount() {
  }

  roundToTwo(num) {
    return +(Math.round(num + "e+2")  + "e-2");
  }

  getChartData(reports) {
    /*
    * Chart data array format: 
    * [datetime, agreement_score, agreements, misses, total, chain]
    */

    return reports
    .map((report) => [
      new Date(report.date).getTime(),
      this.roundToTwo(report.score * 100),
      report.total - report.missed,
      report.missed,
      report.total,
      report.chain,
      ])
    .sort((a,b) => {return a[0] > b[0]});
  }

  render() {
    const { validator, reports } = this.props;

    if (reports && reports.length === 0) {
      return <Loading />
    }

    return (
      <div>
        <Card className="shadow-sm">
          <Card.Body>
          <Card.Title>
            <XIcon icon='chart-area' />
            <span className="pl-2">Validator agreement</span>
          </Card.Title>
            <HighchartsReact
              highcharts={Highcharts}
              constructorType={'stockChart'}
              options={highchartsConfig( this.getChartData(reports), validator )}
            />
          </Card.Body>
        </Card>
      </div>
    );
  }
}

ValidatorChart.propTypes = {
  validator: PropTypes.object.isRequired,
  reports: PropTypes.array.isRequired,
}

export default ValidatorChart;