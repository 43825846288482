import React from 'react';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Badge from 'react-bootstrap/Badge';

const buttonVariations = [
  {
    label: <>Play Slots &amp; win 70,000 XRP❤️</>,
    title: <>XRP Crypto Casino &amp; Sportsbook!</>,
    content: <>Wolf.bet - The best place to play with XRP!
    <ul>
      <li>Over 2,000 Slots Games</li>
      <li>The best Live Casino games</li>
      <li>Daily $1,000 Wagering Race</li>
      <li>Every Friday Bonus code to claim!</li>
    </ul></>,
    href: "https://api.xrpscan.com/api/v1/campaign/wolfplay",
  },
]
let variantIndex = 0;
try {
  variantIndex = Math.floor((Math.random()*10)) % buttonVariations.length;
} catch (error) {}
const buttonVariation = buttonVariations[variantIndex];

const WolfBetButton = () => {
  const popover = (
    <Popover id="popover-sponsor">
      <Popover.Title>
        {buttonVariation.title}
        <span className="small ml-1"><Badge variant="secondary">SPONSORED</Badge></span>
      </Popover.Title>
      <Popover.Content>{buttonVariation.content}</Popover.Content>
    </Popover>
  );

  return (
    <span>
        <OverlayTrigger
          key={'bottom'}
          placement={'bottom'}
          overlay={popover}
        >
          <Button
            variant="info"
            size="sm"
            href={buttonVariation.href}
            target="_blank"
            rel="noopener nofollow"
            className="dropdown-toggle"
          >{buttonVariation.label}</Button>
        </OverlayTrigger>
    </span>
  )
}

export default WolfBetButton;
