import React from 'react';
import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';
import nexoBanner from '../../assets/images/banners/nexo2.2481x200.png';

const BannerAd = () => {
  return (
    <div>
      <Card className="shadow-sm">
        <a
          href="https://api.xrpscan.com/api/v1/campaign/nexobanner"
          rel="noopener noreferrer nofollow"
          target="_blank"
        >
          <Image src={nexoBanner} fluid height={99.8} width={1400}/>
        </a>
      </Card>
    </div>
  );
}

export default BannerAd;