import React from 'react';
import XBadge from "../lib/XBadge";

/*
*  Helper library to parse various flags
*/

/**
 * AccountRoot flags
 */
export const AccountRootFlags = {
    lsfAMM: 0x02000000,
    lsfAllowTrustLineClawback: 0x80000000,
    lsfDefaultRipple: 0x00800000,
    lsfDepositAuth: 0x01000000,
    lsfDisableMaster: 0x00100000,
    lsfDisallowIncomingCheck: 0x08000000,
    lsfDisallowIncomingNFTokenOffer: 0x04000000,
    lsfDisallowIncomingPayChan: 0x10000000,
    lsfDisallowIncomingTrustline: 0x20000000,
    lsfDisallowXRP: 0x00080000,
    lsfGlobalFreeze: 0x00400000,
    lsfNoFreeze: 0x00200000,
    lsfPasswordSpent: 0x00010000,
    lsfRequireAuth: 0x00040000,
    lsfRequireDestTag: 0x00020000,
}

/**
 * Parse AccountRootFlags
 */
export const parseAccountRootFlags = (flags) => {
    const enabledFlags = {}
    if (Number(flags) && flags > 0) {
        if (flags & AccountRootFlags.lsfAMM) enabledFlags["lsfAMM"] = true;
        if (flags & AccountRootFlags.lsfAllowTrustLineClawback) enabledFlags["lsfAllowTrustLineClawback"] = true;
        if (flags & AccountRootFlags.lsfDefaultRipple) enabledFlags["lsfDefaultRipple"] = true;
        if (flags & AccountRootFlags.lsfDepositAuth) enabledFlags["lsfDepositAuth"] = true;
        if (flags & AccountRootFlags.lsfDisableMaster) enabledFlags["lsfDisableMaster"] = true;
        if (flags & AccountRootFlags.lsfDisallowIncomingCheck) enabledFlags["lsfDisallowIncomingCheck"] = true;
        if (flags & AccountRootFlags.lsfDisallowIncomingNFTokenOffer) enabledFlags["lsfDisallowIncomingNFTokenOffer"] = true;
        if (flags & AccountRootFlags.lsfDisallowIncomingPayChan) enabledFlags["lsfDisallowIncomingPayChan"] = true;
        if (flags & AccountRootFlags.lsfDisallowIncomingTrustline) enabledFlags["lsfDisallowIncomingTrustline"] = true;
        if (flags & AccountRootFlags.lsfDisallowXRP) enabledFlags["lsfDisallowXRP"] = true;
        if (flags & AccountRootFlags.lsfGlobalFreeze) enabledFlags["lsfGlobalFreeze"] = true;
        if (flags & AccountRootFlags.lsfNoFreeze) enabledFlags["lsfNoFreeze"] = true;
        if (flags & AccountRootFlags.lsfPasswordSpent) enabledFlags["lsfPasswordSpent"] = true;
        if (flags & AccountRootFlags.lsfRequireAuth) enabledFlags["lsfRequireAuth"] = true;
        if (flags & AccountRootFlags.lsfRequireDestTag) enabledFlags["lsfRequireDestTag"] = true;
    }
    return enabledFlags;
}

/**
 * RippleState flags
 */
export const RippleStateFlags = {
    lsfLowReserve: 0x00010000,
    lsfHighReserve: 0x00020000,
    lsfLowAuth: 0x00040000,
    lsfHighAuth: 0x00080000,
    lsfLowNoRipple: 0x00100000,
    lsfHighNoRipple: 0x00200000,
    lsfLowFreeze: 0x00400000,
    lsfHighFreeze: 0x00800000,
}

// Parse RippleStateFlags
export const parseRippleStateFlags = (flags) => {
    const enabledFlags = {}
    if (Number(flags) && flags > 0) {
        if (flags & RippleStateFlags.lsfLowReserve) enabledFlags["lsfLowReserve"] = true;
        if (flags & RippleStateFlags.lsfHighReserve) enabledFlags["lsfHighReserve"] = true;
        if (flags & RippleStateFlags.lsfLowAuth) enabledFlags["lsfLowAuth"] = true;
        if (flags & RippleStateFlags.lsfHighAuth) enabledFlags["lsfHighAuth"] = true;
        if (flags & RippleStateFlags.lsfLowNoRipple) enabledFlags["lsfLowNoRipple"] = true;
        if (flags & RippleStateFlags.lsfHighNoRipple) enabledFlags["lsfHighNoRipple"] = true;
        if (flags & RippleStateFlags.lsfLowFreeze) enabledFlags["lsfLowFreeze"] = true;
        if (flags & RippleStateFlags.lsfHighFreeze) enabledFlags["lsfHighFreeze"] = true;
    }
    return enabledFlags;
}

/**
 * NFTokenOffer flags
 */
export const NFTokenOfferFlags = {
    lsfSellNFToken: 0x00000001,
}

// Parse NFTokenOfferFlags
export const parseNFTokenOfferFlags = (flags) => {
    const enabledFlags = {}
    if (Number(flags) && flags > 0) {
        if (flags & NFTokenOfferFlags.lsfSellNFToken) enabledFlags["lsfSellNFToken"] = true;
    }
    return enabledFlags;
}

/**
 * Offer flags
 */
export const OfferFlags = {
    lsfPassive: 0x00010000,
    lsfSell: 0x00020000,
}

// Parse OfferFlags
export const parseOfferFlags = (flags) => {
    const enabledFlags = {}
    if (Number(flags) && flags > 0) {
        if (flags & OfferFlags.lsfPassive) enabledFlags["lsfPassive"] = true;
        if (flags & OfferFlags.lsfSell) enabledFlags["lsfSell"] = true;
    }
    return enabledFlags;
}

/**
 * SignerList flags
 */
export const SignerListFlags = {
    lsfOneOwnerCount: 0x00010000,
}

// Parse SignerListFlags
export const parseSignerListFlags = (flags) => {
    const enabledFlags = {}
    if (Number(flags) && flags > 0) {
        if (flags & SignerListFlags.lsfOneOwnerCount) enabledFlags["lsfOneOwnerCount"] = true;
    }
    return enabledFlags;
}

// Returns a component with a list of tags defined by flags parsed by parser
export const getFlagTag = (Flags, Parser) => {
    return Object.keys(Parser(Flags)).map((flag, index) =>
        <XBadge key={index} variant="info" className="mr-2 text-monospace">{flag}</XBadge>
    );
}