import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Card from 'react-bootstrap/Card';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { LinkContainer } from 'react-router-bootstrap';
import { Helmet } from 'react-helmet';
import { XRPSCAN_API_URL } from '../../config';
import { handleResponse } from '../common/Helpers';
import MetricMetric from './MetricMetric';
import MetricType from './MetricType';
import MetricResult from './MetricResult';
import Analytics from '../common/Analytics';
import CardTitleContainer from '../lib/CardTitleContainer';
import IntegrationGuide from '../common/IntegrationGuide';
import SponsorContainer from '../sponsorship/SponsorContainer';
import MetricAMM from './MetricAMM';

class Metric extends React.Component {
  constructor(props) {
    super(props);
    this.handleSelect = this.handleSelect.bind(this);
    this.state = {
      key: 'metric',
      metric: [],
      type: [],
      result: [],
      amm: [],
    }
  }

  // React view methods
  componentDidMount() {
    this.fetchMetrics(this.state.key);
    Analytics.pageview();
  }

  handleSelect(key) {
    this.setState({
      key: key,
      metric: [],
      type: [],
      result: [],
      amm: [],
    });
    this.fetchMetrics(key);
  }

  fetchMetrics(key) {
    fetch(`${XRPSCAN_API_URL}/metrics/${key}`)
      .then(handleResponse)
      .then((data) => {
        switch (key) {
          case 'metric':
            this.setState({metric: data});
            break;
          case 'type':
            this.setState({type: data});
            break;
          case 'result':
            this.setState({result: data});
            break;
          case 'amm':
            this.setState({amm: data});
            break;
          default:
            break;
        }
      })
      .catch((error) => {
        this.setState({
          error: error.errorMessage,
        })        
      });
  }

  render() {
    const { 
      metric,
      type,
      result,
      amm,
    } = this.state;

    return (
      <div>
        <Helmet>
          <title>XRP Ledger Metrics | XRPSCAN</title>
        </Helmet>
				<Row>
					<Col>
						<IntegrationGuide/>
					</Col>
				</Row>
				<Row>
					<Col xs={12} md={4}>
            <Breadcrumb>
              <LinkContainer to={`/`}><Breadcrumb.Item>Home</Breadcrumb.Item></LinkContainer>
              <Breadcrumb.Item active>Metrics</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
					<Col xs={12} md={8}>
						<SponsorContainer />
					</Col>
				</Row>
        <Row>
          <Col xs={12} md={12}>
            <Card>
              <Card.Body>
                <Card.Title>
                  <CardTitleContainer icon="chart-area" title="XRP Ledger metrics" />
                </Card.Title>
                <hr/>
                <Tabs
                  activekey={this.state.key}
                  defaultActiveKey={'metric'}
                  onSelect={this.handleSelect}
                  transition={false}
                  mountOnEnter={true}
                  unmountOnExit={true}
                  id="transaction-metrics"
                  className="metrics-tab"
                >
                  <Tab eventKey="metric" title="Aggregate">
                    <MetricMetric metric={metric} />
                  </Tab>
                  <Tab eventKey="type" title="Transaction Types">
                    <MetricType metric={type} />
                  </Tab>
                  <Tab eventKey="result" title="Results">
                    <MetricResult metric={result} />
                  </Tab>
                  <Tab eventKey="amm" title="AMM">
                    <MetricAMM metric={amm} />
                  </Tab>
                </Tabs>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

export default Metric;