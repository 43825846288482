import React from 'react';
import PropTypes from 'prop-types';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Tooltip from 'react-bootstrap/Tooltip';
import { Link } from 'react-router-dom';
import XIcon from '../lib/XIcon';

const ValidatorTag = (props) => {
  const {
    validator,
    minimal,  
    verificationIcon,
  } = props;

  const getDomainVerificationPopover = (validator) => {
    if (validator && validator.meta && validator.meta.verified) {
      return (
        <Popover id={`popover-${validator.master_key}`} variant="success">
          <Popover.Title as="h6">Verified</Popover.Title>
        </Popover>
      );  
    }
    else if (validator && validator.meta && validator.meta.verification_message) {
      return (
        <Popover id={`popover-${validator.master_key}`}>
          <Popover.Title as="h6">Verification failed</Popover.Title>
          <Popover.Content>
              <a href={getTOMLURL(validator.domain)} rel="nofollow noopener noreferrer">
                {getTOMLURL(validator.domain)}
              </a> - {validator.meta.verification_message}
          </Popover.Content>
        </Popover>
      );
    }
    else {
      return (<></>);
    }
  }

  const getTOMLURL = (domain) => {
    return `https://${domain}/.well-known/xrp-ledger.toml`;
  }

  /*
  * Compute what will be displayed as validator name
  */
  let validatorName = null;
  if (validator.domain && typeof validator.domain === 'string' && validator.meta) {
    validatorName = 
      <>
      <Link to={`/validator/${validator.master_key}`}>{validator.domain}</Link>
      {verificationIcon &&
      <OverlayTrigger
        key="right"
        placement="bottom"
        overlay={getDomainVerificationPopover(validator)}
      >
        <span className="ml-2">
          {validator.meta && validator.meta.verified &&
            <XIcon icon="check" className="text-success"/>
          }
          {validator.meta && !validator.meta.verified &&
            <XIcon icon="times" className="text-danger"/>
          }
        </span>
      </OverlayTrigger>
      }
      </>
  }
  else if (validator.domain_legacy && typeof validator.domain_legacy === 'string') {
    validatorName = 
      <>
        <Link to={`/validator/${validator.master_key}`}>
          {validator.domain_legacy}
        </Link>
        {verificationIcon &&
        <OverlayTrigger
          key="right"
          placement="bottom"
          overlay={
            <Tooltip id="tooltip-domain-right">
              Verified via legacy verification method
            </Tooltip>
          }
        >
          <span className="ml-2">
            <XIcon icon="exclamation" faStyle="fad" className="text-warning"/>
          </span>
        </OverlayTrigger>
        }
      </>
  }
  else {
    validatorName =
      <Link to={`/validator/${validator.master_key}`}>
        {props.children}
      </Link>
  }

  /* 
  * Minimal style account name or full validator master_key?
  */
  const styledValidatorName = minimal ?
    <span className="address-tag">{validatorName}</span> :
    validatorName;

  return <span className="text-monospace text-nowrap address-name">{styledValidatorName}</span>;
}

ValidatorTag.defaultProps = {
  minimal: false,
  verificationIcon: true,
}

ValidatorTag.propTypes = {
  validator: PropTypes.shape({
    master_key: PropTypes.string,
    domain: PropTypes.string,
    domain_legacy: PropTypes.string,
    meta: {
      verified: PropTypes.bool,
      verification_message: PropTypes.string,    
    }
  }),
  minimal: PropTypes.bool,
  verificationIcon: PropTypes.bool,
}

export default ValidatorTag;