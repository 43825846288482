import React from 'react';
import PropTypes from 'prop-types';
import { hex2ascii, LedgerEntryType } from '../../common/Helpers';

const DID = (props) => {
	const { node } = props;
    if ( node?.LedgerEntryType === LedgerEntryType.DID ) {
        return (
            <>
                {node?.DIDDocument &&
                <tr>
                    <td>DIDDocument:</td>
                    <td>
                        <span>
                            {hex2ascii(node.DIDDocument)}
                        </span>
                    </td>
                </tr>
                }

                {node?.Data &&
                <tr>
                    <td>Data:</td>
                    <td>
                        <span>
                            {hex2ascii(node.Data)}
                        </span>
                    </td>
                </tr>
                }
            </>
        );    
    } else {
        return <></>;
    }
}

DID.propTypes = {
	node: PropTypes.object,
}

export default DID;