import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { LinkContainer } from 'react-router-bootstrap';
import { Helmet } from 'react-helmet';
import { FormattedNumber } from 'react-intl';
import { XRPSCAN_API_URL } from '../../config';
import { handleResponse } from '../common/Helpers';
import TransactionInfo from './TransactionInfo';
import TransactionGraph from './TransactionGraph';
import Loading from '../common/Loading';
import IntegrationGuide from '../common/IntegrationGuide';
import SponsorContainer from '../sponsorship/SponsorContainer';
import Analytics from '../common/Analytics';
import TextAd from '../sponsorship/TextAd';

class Transaction extends React.Component {
	constructor() {
		super();

		this.state = {
			tx: {},
			loading: false,
			error: null,
		}
	}

	componentDidMount() {
		const hash = this.props.match.params.hash;
		if (hash && hash.length > 0 && /[a-f]/.test(hash)) {
			this.props.history.push(`/tx/${hash.toUpperCase()}`);
		}
		else {
			this.fetchTransaction(hash);
	    Analytics.pageview();
		}
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.location.pathname !== nextProps.location.pathname) {
			const newHash = nextProps.match.params.hash;
			this.fetchTransaction(newHash);
	    Analytics.pageview();
		}
	}

	fetchTransaction(hash) {
		this.setState({ loading: true })

		fetch(`${XRPSCAN_API_URL}/tx/${hash}`)
			.then(handleResponse)
			.then((data) => {
				this.setState({
					loading: false,
					tx: data,
					error: null,
				});
			})
			.catch((error) => {
				this.setState({
					loading: false,
					error: error.errorMessage,
				});
			});
	}

	render() {
		const { tx, loading } = this.state;

		// render only loading component if loading state is set to true
		if (loading) {
			return <Loading />
		}

		return (
			<div>
				<Helmet>
					<title>{`${tx.hash || 'Not found'}`} - XRP Ledger transaction hash | XRPSCAN</title>
				</Helmet>
				<Row>
					<Col>
						<IntegrationGuide/>
					</Col>
				</Row>
				<Row>
					<Col xs={12} md={4}>
						<Breadcrumb>
							<LinkContainer to={`/`}><Breadcrumb.Item>Home</Breadcrumb.Item></LinkContainer>
							{tx.ledger_index &&
							<LinkContainer to={`/ledger/${tx.ledger_index}`}>
								<Breadcrumb.Item><FormattedNumber value={tx.ledger_index} /></Breadcrumb.Item>
							</LinkContainer>
							}
							<Breadcrumb.Item active className="address-tag address-tag-lg">{tx.hash}</Breadcrumb.Item>
						</Breadcrumb>
					</Col>
					<Col xs={12} md={8}>
						<SponsorContainer />
					</Col>
				</Row>
				<Row>
					<Col xs={12} md={12}>
						<TransactionInfo tx={tx} />
						<Row>
							<Col xs={12} md={12}>
								<TextAd/>
							</Col>
						</Row>
						{tx.meta && tx.meta.AffectedNodes && tx.meta.AffectedNodes.length > 0 &&
							<TransactionGraph affectedNodes={tx.meta.AffectedNodes} />
						}
					</Col>
				</Row>
			</div>
		)
	}
}

export default Transaction;