import React from 'react';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Badge from 'react-bootstrap/Badge';

const buttonVariations = [
  {
    label: <>Secure Your XRP</>,
    title: <>Self Custody = Your Responsibility</>,
    content: <>
        XRPL Firewall - Learn more and discover what you need to do to bring game-changing protection to your XRP. Join the waitlist today! First 2 months free for the first 2,000 users, upon launch.
    </>,
    href: "https://api.xrpscan.com/api/v1/campaign/xrplfirewall1",
  },
]
let variantIndex = 0;
try {
  variantIndex = Math.floor((Math.random()*10)) % buttonVariations.length;
} catch (error) {}
const buttonVariation = buttonVariations[variantIndex];

const XRPLFirewallButton = () => {
  const popover = (
    <Popover id="popover-sponsor">
      <Popover.Title>
        {buttonVariation.title}
        <span className="small ml-1"><Badge variant="secondary">SPONSORED</Badge></span>
      </Popover.Title>
      <Popover.Content>{buttonVariation.content}</Popover.Content>
    </Popover>
  );

  return (
    <span>
        <OverlayTrigger
          key={'bottom'}
          placement={'bottom'}
          overlay={popover}
        >
          <Button
            variant="info"
            size="sm"
            href={buttonVariation.href}
            target="_blank"
            rel="noopener nofollow"
            className="dropdown-toggle"
          >{buttonVariation.label}</Button>
        </OverlayTrigger>
    </span>
  )
}

export default XRPLFirewallButton;