import React from 'react';
import Table from 'react-bootstrap/Table';
import PropTypes from 'prop-types';
import { LedgerEntryType, feeUnitToPercent } from '../../common/Helpers';
import AMMAssetTag from '../../amm/AMMAssetTag';
import XBadge from '../../lib/XBadge';
import Money from '../../lib/Money';
import { FormattedNumber } from 'react-intl';
import AccountTag from '../../lib/AccountTag';

const AMM = (props) => {
	const { node } = props;
    if ( node?.LedgerEntryType === LedgerEntryType.AMM ) {
        return (
            <>
                {node?.Asset &&
                <tr>
                    <td>Asset:</td>
                    <td>
                        <span>
                        <AMMAssetTag>{node?.Asset}</AMMAssetTag>
                        </span>
                    </td>
                </tr>
                }

                {node?.Asset2 &&
                <tr>
                    <td>Asset2:</td>
                    <td>
                        <span>
                        <AMMAssetTag>{node?.Asset2}</AMMAssetTag>
                        </span>
                    </td>
                </tr>
                }

                {node?.TradingFee >= 0 &&
                <tr>
                    <td>TradingFee:</td>
                    <td>
                        <span>
                            {node.TradingFee}
                            <XBadge variant="dark" className="ml-2"><FormattedNumber value={node?.TradingFee / 1000} minimumFractionDigits={3}></FormattedNumber>%</XBadge>
                        </span>
                    </td>
                </tr>
                }

                {node?.LPTokenBalance?.currency &&
                <tr>
                    <td>LPToken:</td>
                    <td>
                        <span className='text-muted text-monospace'>{node?.LPTokenBalance.currency}</span>
                    </td>
                </tr>
                }

                {node?.LPTokenBalance && Object.keys(node.LPTokenBalance).length > 0 &&
                <tr>
                    <td>LPTokenBalance:</td>
                    <td>
                        <Money
                            currency={node?.LPTokenBalance.currency}
                            issuer={node?.LPTokenBalance.issuer}
                            value={node?.LPTokenBalance.value}
                        />
                    </td>
                </tr>
                }

                {node?.AuctionSlot &&
                <tr>
                    <td>AuctionSlot:</td>
                    <td>
                        <Table responsive>
                            <tbody>
                                {node?.AuctionSlot?.Account &&
                                <tr>
                                    <td>Account:</td>
                                    <td><AccountTag link>{node?.AuctionSlot?.Account}</AccountTag></td>
                                </tr>
                                }
                                {node?.AuctionSlot?.DiscountedFee &&
                                <tr>
                                    <td>DiscountedFee:</td>
                                    <td>
                                        {node?.AuctionSlot?.DiscountedFee}
                                        <XBadge variant="primary" className="ml-2">{feeUnitToPercent(node?.AuctionSlot?.DiscountedFee)}%</XBadge>
                                    </td>
                                </tr>
                                }
                                {node?.AuctionSlot?.Expiration &&
                                <tr>
                                    <td>Expiration:</td>
                                    <td>{node?.AuctionSlot?.Expiration}</td>
                                </tr>
                                }
                                {node?.AuctionSlot?.Price &&
                                <tr>
                                    <td>Price:</td>
                                    <td>
                                            <Money
                                                currency={node?.AuctionSlot?.Price.currency}
                                                issuer={node?.AuctionSlot?.Price.issuer}
                                                value={node?.AuctionSlot?.Price.value}
                                            />
                                        </td>
                                </tr>
                                }
                            </tbody>
                        </Table>
                    </td>
                </tr>
                }

                {node?.VoteSlots &&
                <tr>
                    <td>VoteSlots:</td>
                    <td>
                        <Table responsibe>
                           <thead>
                               <tr>
                                    <th>VoteEntry</th>
                                    <th>Account</th>
                                    <th className="text-right">TradingFee</th>
                                    <th className="text-right">VoteWeight</th>
                                </tr>
                            </thead>
                            <tbody>
                                {node.VoteSlots.map((VoteSlot, index) => {
                                    return(
                                        <tr>
                                            <td>{index}</td>
                                            <td><AccountTag link>{VoteSlot?.VoteEntry?.Account}</AccountTag></td>
                                            <td className="text-right">{VoteSlot?.VoteEntry?.TradingFee}</td>
                                            <td className="text-right">{VoteSlot?.VoteEntry?.VoteWeight}</td>
                                        </tr>
                                    )
                                    })
                                }
                            </tbody>
                        </Table>
                    </td>
                </tr>
                }                
            </>
        );    
    } else {
        return <></>;
    }
}

AMM.propTypes = {
	node: PropTypes.object,
}

export default AMM;