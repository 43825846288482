/**
* Constants used in XRP Ledger.
*/

export const NATIVE_CURRENCY = 'XRP';
Object.freeze(NATIVE_CURRENCY);

export const DROPS_PER_XRP = 1000000.0;
Object.freeze(DROPS_PER_XRP);

export const LPTOKEN_PREFIX = 'LP'
Object.freeze(LPTOKEN_PREFIX);

export const ACCOUNTDELETE_ENABLE_LEDGER = 55313921;
Object.freeze(ACCOUNTDELETE_ENABLE_LEDGER);

/**
* Enum of all Transaction Types supported by XRPL
*
*/

const TransactionType = {
  AccountSet: 'AccountSet',
  AccountDelete: 'AccountDelete',
  AMMBid: 'AMMBid',
  AMMCreate: 'AMMCreate',
  AMMDelete: 'AMMDelete',
  AMMDeposit: 'AMMDeposit',
  AMMVote: 'AMMVote',
  AMMWithdraw: 'AMMWithdraw',
  CheckCancel: 'CheckCancel',
  CheckCash: 'CheckCash',
  CheckCreate: 'CheckCreate',
  Clawback: 'Clawback',
  DepositPreauth: 'DepositPreauth',
  DIDDelete: 'DIDDelete',
  DIDSet: 'DIDSet',
  EscrowCancel: 'EscrowCancel',
  EscrowCreate: 'EscrowCreate',
  EscrowFinish: 'EscrowFinish',
  NFTokenAcceptOffer: 'NFTokenAcceptOffer',
  NFTokenBurn: 'NFTokenBurn',
  NFTokenCancelOffer: 'NFTokenCancelOffer',
  NFTokenCreateOffer: 'NFTokenCreateOffer',
  NFTokenMint: 'NFTokenMint',
  OfferCancel: 'OfferCancel',
  OfferCreate: 'OfferCreate',
  OracleDelete: 'OracleDelete',
  OracleSet: 'OracleSet',
  Payment: 'Payment',
  PaymentChannelClaim: 'PaymentChannelClaim',
  PaymentChannelCreate: 'PaymentChannelCreate',
  PaymentChannelFund: 'PaymentChannelFund',
  SetRegularKey: 'SetRegularKey',
  SignerListSet: 'SignerListSet',
  TicketCreate: 'TicketCreate',
  TrustSet: 'TrustSet',
  EnableAmendment: 'EnableAmendment',
  SetFee: 'SetFee',
  UNLModify: 'UNLModify',
};
Object.freeze(TransactionType);
export { TransactionType };

export const NFTokenMintFlags = {
  tfBurnable: 'tfBurnable',
  tfOnlyXRP: 'tfOnlyXRP',
  tfTrustLine: 'tfTrustLine',
  tfTransferable: 'tfTransferable',
}

// Enum of supported Advisory types
const AdvisoryType = {
  SPAM: 'SPAM',
  HACK: 'HACK',
  LEA: 'LEA',
}
Object.freeze(AdvisoryType)
export { AdvisoryType }

export const AdvisoryTypes = [
  AdvisoryType.SPAM,
  AdvisoryType.HACK,
  AdvisoryType.LEA,
]
Object.freeze(AdvisoryTypes)

export const XrplNetwork = {
  XahauTestnet: 21338,
  XahauMainnet: 21337,
}
Object.freeze(XrplNetwork)

export const XrplNetworkUrls = {
  XahauTestnet: "https://test.xahscan.com",
  XahauMainnet: "https://xahscan.com",
}
Object.freeze(XrplNetworkUrls)

export const Burn2MintTransactionTypes = [
  TransactionType.AccountSet,
  TransactionType.SetRegularKey,
  TransactionType.SignerListSet,
]
Object.freeze(Burn2MintTransactionTypes)

export const AccountSettingDescription = {
  defaultRipple: "This account allows rippling on its trust lines by default",
  depositAuth: "This account is using Deposit Authorization and does not accept any payments from unknown parties",
  disableMasterKey: "This account's master key pair is disabled",
  disallowIncomingCheck: "This account does not allow others to send Checks to it",
  disallowIncomingNFTokenOffer: "This account does not allow others to make NFT buy or sell offers to it",
  disallowIncomingPayChan: "This account does not allow others to make Payment Channels to it",
  disallowIncomingTrustline: "This account does not allow others to make trust lines to it",
  disallowIncomingXRP: "This account does not want to receive XRP from others. (This is advisory, and not enforced at a protocol level.)",
  globalFreeze: "All tokens issued by this account are currently frozen",
  noFreeze: "This account has permanently given up the abilities to freeze individual trust lines or end a global freeze",
  passwordSpent: "This account has used its free SetRegularKey transaction",
  requireAuthorization: "This account is using Authorized Trust Lines to limit who can hold the tokens it issues",
  requireDestinationTag: "This account requires a destination tag on all payments it receives",
}
Object.freeze(AccountSettingDescription)
