import React from 'react';
import PropTypes from 'prop-types';
import Card from 'react-bootstrap/Card';
import { XRPSCAN_API_URL } from '../../config';
import { handleResponse } from '../common/Helpers';
import Loading from '../common/Loading';
import Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';
import HC_accessibility from 'highcharts/modules/accessibility';
import HighchartsReact from 'highcharts-react-official';

const highchartsConfig = (account, direction='out', payments=[], volumes=[]) => {
  // Chart labels
  let titleAccount = account.accountName ? account.accountName.name : account.account;
  let titleDirection = direction === 'out' ? 'Outward payments from' : 'Inward payments to';
  let titleText = `${titleDirection} ${titleAccount}`;
  return {
    title: { text: titleText },
    subtitle: { text: '(Over last 30 days)' },
    tooltip: { shared: true },

    // Axis definition
    xAxis: [{
      labels: { format: '{value:%b %e}' },
      title: { text: 'Time' },
      crosshair: true,
      type: 'datetime',
    }],
    yAxis: [{
      title: { text: 'Payments' },
      labels: {
        formatter: function() {
          if ( this.value >= 1000 )
            return Highcharts.numberFormat( this.value/1000, 1) + " k";
          else
            return Highcharts.numberFormat(this.value, 0);
        },
      },
      maxPadding: 0.25,
      min: 0,
    },
    {
      title: { text: 'Volume' },
      labels: {
        formatter: function() {
          if ( this.value >= 1000 * 1000 )
            return Highcharts.numberFormat( this.value/1000000, 1) + " M XRP";
          if ( this.value >= 1000 )
            return Highcharts.numberFormat( this.value/1000, 1) + " K XRP";
          else
            return Highcharts.numberFormat(this.value, 0);
        },
      },
      maxPadding: 0.25,
      min: 0,
      opposite: true,
    }],

    // Chart settings
    chart: {
      type: 'area',
      zoomType: 'x',
      panning: true,
      panKey: 'ctrl',
      scrollablePlotArea: { minWidth: 600 },
    },

    // credits: {
    //   enabled: false
    // },

    // No data settings
    lang: {
        noData: "No data available"
    },
    noData: {
        style: {
            fontWeight: 'bold',
            fontSize: '15px',
            color: '#303030'
        }
    },

    // Plot settings
    plotOptions: {
      area: {
        pointStart: 0,
        marker: {
          enabled: false,
          symbol: 'triangle',
          radius: 2,
          states: {
            hover: { enabled: true },
          }
        }
      }
    },
    series: [
    {
      name: 'Payments',
      type: 'spline',
      tooltip: { valueSuffix: '' },
      data: payments,
    },
    {
      name: 'Volume',
      lineColor: Highcharts.getOptions().colors[0],
      color: Highcharts.getOptions().colors[2],
      fillOpacity: 0.5,
      threshold: null,
      yAxis: 1,
      tooltip: { valueSuffix: ' XRP' },
      data: volumes,
    }
    ]
  }
};

class AccountAnalysis extends React.Component {
  constructor(props) {
    super(props);
    HC_exporting(Highcharts);
    HC_accessibility(Highcharts);

    this.state = {
      account: this.props.account,
      chartConfigIn: highchartsConfig(this.props.address, 'in'),
      chartConfigOut: highchartsConfig(this.props.address, 'out'),
      loadingIn: false,
      loadingOut: false,
    }
  }

  componentDidMount() {
    this.fetchPaymentFlows();
  }

  fetchPaymentFlows() {
    this.setState({
      loadingIn: true,
      loadingOut: true,
    })
    const { account } = this.state;

    // Load payment_flow IN
    fetch(`${XRPSCAN_API_URL}/account/${account.account}/payment_flow/in`)
      .then(handleResponse)
      .then((data) => {
        this.setState({
          chartConfigIn: highchartsConfig(account, 'in', this.getPaymentSeries(data), this.getVolumeSeries(data)),
          loadingIn: false,
        })
      })
      .catch((error) => {
        this.setState({
          loadingIn: false,
        })
    });

    // Load payment_flow OUT
    fetch(`${XRPSCAN_API_URL}/account/${account.account}/payment_flow/out`)
      .then(handleResponse)
      .then((data) => {
        this.setState({
          chartConfigOut: highchartsConfig(account, 'out', this.getPaymentSeries(data), this.getVolumeSeries(data)),
          loadingOut: false,
        })
      })
      .catch((error) => {
        this.setState({
          loadingOut: false,
      })
    });
  }

  getPaymentSeries(data) {
    return data.map((date) => [new Date(date._id).getTime(), date.payments]);
  }

  getVolumeSeries(data) {
    return data.map((date) => [new Date(date._id).getTime(), date.volume]);
  }

  render() {
    const {
      chartConfigIn,
      chartConfigOut,
      loadingIn,
      loadingOut
    } = this.state;

    if (loadingIn || loadingOut) {
      return <Loading />
    }

    return (
      <div className='mt-4'>
        <Card className="shadow-sm">
          <Card.Body>
            <HighchartsReact
              highcharts={Highcharts}
              options={chartConfigIn}
            />
          </Card.Body>
        </Card>
        <Card className="shadow-sm">
          <Card.Body>
            <HighchartsReact
              highcharts={Highcharts}
              options={chartConfigOut}
            />
          </Card.Body>
        </Card>
      </div>
    )
  }
}

AccountAnalysis.propTypes = {
  account: PropTypes.object.isRequired,
}

export default AccountAnalysis;