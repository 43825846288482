import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Helmet } from 'react-helmet';
import { LinkContainer } from 'react-router-bootstrap';
import SponsorContainer from '../sponsorship/SponsorContainer';
import EmptyState from '../lib/EmptyState';
import Analytics from '../common/Analytics';

export const EmptyTransaction = () => {
  Analytics.pageview();
  return (
    <EmptyState title="404">Oops, we couldn't find this transaction object!</EmptyState>
  );  
}

const NotFound = () => {
  Analytics.pageview();
	return (
    <div>
      <Helmet>
        <title>Not found | XRPSCAN</title>
      </Helmet>
      <Row>
        <Col xs={12} md={4}>
          <Breadcrumb>
            <LinkContainer to={`/`}><Breadcrumb.Item>Home</Breadcrumb.Item></LinkContainer>
            <Breadcrumb.Item active>404</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col xs={12} md={8}>
          <SponsorContainer />
        </Col>
      </Row>
      <EmptyState title="404: Not found">Oh no! We searched everywhere, but its nowhere to be found.</EmptyState>
    </div>
	);
}

export default NotFound;