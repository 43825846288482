import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { LedgerEntryType } from '../../common/Helpers';
import AccountTag from '../../lib/AccountTag';
import Money from '../../lib/Money';
import { getFlagTag, parseAccountRootFlags } from '../../common/FlagHelpers';

const AccountRoot = (props) => {
	const { node } = props;
    if ( node?.LedgerEntryType === LedgerEntryType.AccountRoot ) {
        return (
            <>
                {node?.AMMID &&
                <tr>
                    <td>AMMID:</td>
                    <td>
                        <span>
                            <Link to={`/tx/${node.AMMID}`}>{node.AMMID}</Link>
                        </span>
                    </td>
                </tr>
                }

                {node?.Balance >= 0 &&
                <tr>
                    <td>Balance:</td>
                    <td>
                        <span>
                            <Money value={node.Balance} drops />
                        </span>
                    </td>
                </tr>
                }

                {node?.BurnedNFTokens >= 0 &&
                <tr>
                    <td>BurnedNFTokens:</td>
                    <td>
                        <span>
                            {node.BurnedNFTokens}
                        </span>
                    </td>
                </tr>
                }

                {node?.Domain &&
                <tr>
                    <td>Domain:</td>
                    <td>
                        <span>
                            {node.Domain}
                        </span>
                    </td>
                </tr>
                }

                {node?.EmailHash &&
                <tr>
                    <td>EmailHash:</td>
                    <td>
                        <span>
                            {node.EmailHash}
                        </span>
                    </td>
                </tr>
                }

                {node?.FirstNFTokenSequence >= 0 &&
                <tr>
                    <td>FirstNFTokenSequence:</td>
                    <td>
                        <span>
                            {node.FirstNFTokenSequence}
                        </span>
                    </td>
                </tr>
                }

                {node?.MessageKey &&
                <tr>
                    <td>MessageKey:</td>
                    <td>
                        <span>
                            {node.MessageKey}
                        </span>
                    </td>
                </tr>
                }

                {node?.MintedNFTokens >= 0 &&
                <tr>
                    <td>MintedNFTokens:</td>
                    <td>
                        <span>
                            {node.MintedNFTokens}
                        </span>
                    </td>
                </tr>
                }

                {node?.NFTokenMinter &&
                <tr>
                    <td>NFTokenMinter:</td>
                    <td>
                        <span>
                            <AccountTag link>{node.NFTokenMinter}</AccountTag>
                        </span>
                    </td>
                </tr>
                }

                {node?.OwnerCount >= 0 &&
                <tr>
                    <td>OwnerCount:</td>
                    <td>
                        <span>
                            {node.OwnerCount}
                        </span>
                    </td>
                </tr>
                }

                {node?.RegularKey &&
                <tr>
                    <td>RegularKey:</td>
                    <td>
                        <span>
                            <AccountTag link>{node.RegularKey}</AccountTag>
                        </span>
                    </td>
                </tr>
                }

                {node?.TicketCount >= 0 &&
                <tr>
                    <td>TicketCount:</td>
                    <td>
                        <span>
                            {node.TicketCount}
                        </span>
                    </td>
                </tr>
                }

                {node?.TickSize >= 0 &&
                <tr>
                    <td>TickSize:</td>
                    <td>
                        <span>
                            {node.TickSize}
                        </span>
                    </td>
                </tr>
                }

                {node?.TransferRate >= 0 &&
                <tr>
                    <td>TransferRate:</td>
                    <td>
                        <span>
                            {node.TransferRate}
                        </span>
                    </td>
                </tr>
                }

                {node?.WalletLocator &&
                <tr>
                    <td>WalletLocator:</td>
                    <td>
                        <span>
                            {node.WalletLocator}
                        </span>
                    </td>
                </tr>
                }

                {node?.WalletSize >= 0 &&
                <tr>
                    <td>WalletSize:</td>
                    <td>
                        <span>
                            {node.WalletSize}
                        </span>
                    </td>
                </tr>
                }

                {node?.Flags > 0 &&
                <tr>
                    <td>Flags:</td>
                    <td>
                        {getFlagTag(node.Flags, parseAccountRootFlags)}
                    </td>
                </tr>
                }
            </>
        );
    } else {
        return <></>;
    }
}

AccountRoot.propTypes = {
	node: PropTypes.object,
}

export default AccountRoot;