import React from 'react';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';
import XBadge from '../lib/XBadge';
import { getRandomElement } from '../common/Helpers';
import moonpayLogo from '../../assets/images/moonpay.32.png';

const adVariations = [
  {
    label: <>Buy now on Moonpay &rarr;</>,
    title: <>Moonpay &mdash; Buy crypto with a non-custodial and fully decentralised platform.</>,
    href: "https://api.xrpscan.com/api/v1/campaign/moonpaytext",
  },
];
const adVariation = getRandomElement(adVariations);

const TextAd = () => {
  return (
    <div>
      <Card className="shadow-sm" border="info">
        <Card.Body>
          <XBadge variant="light"><small>Sponsored:</small></XBadge>
          <Image alt="Moonpay logo" src={moonpayLogo} height="18" className="ml-1" />
          <span className="ml-1">{adVariation.title}</span>
          <span className="ml-2"><a href={adVariation.href} target="_blank" rel="noopener noreferrer">{adVariation.label}</a></span>
        </Card.Body>
      </Card>
    </div>
  );
}

export default TextAd;
