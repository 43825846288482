import React, { useMemo, useState, useEffect } from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import Money from '../lib/Money';
import AccountTag from '../lib/AccountTag';
import EmptyStateNoRecord from '../lib/EmptyStateNoRecord';
import Loading from '../common/Loading';
import { XRPSCAN_API_URL } from '../../config';
import { handleResponse } from '../common/Helpers';
import AdvancedTable from '../lib/AdvancedTable';
import AMMAssetTag from './AMMAssetTag';
import XBadge from '../lib/XBadge';
import XIcon from '../lib/XIcon';
import { FormattedNumber } from 'react-intl';

/*
* Add custom properties to represent Money etc.
*/
const addProperties = (_amms) => {
	const populatedAMMs = _amms.map((amm, idx) => {
        amm.idx = idx + 1

        if (amm?.AuctionSlot?.Account) {
            amm.AuctionSlotOwner = <AccountTag link minimal>{amm.AuctionSlot.Account}</AccountTag>
        }

		if (amm?.AssetName || amm?.Asset2Name) {
			let quoteAsset = <AMMAssetTag>{amm.Asset}</AMMAssetTag>
			let baseAsset = <AMMAssetTag>{amm.Asset2}</AMMAssetTag>

			// If the asset issuer was found in names db, account property is
			// present in the responseß
			if (amm?.AssetName?.account) {
				quoteAsset = amm.AssetName.username ? `~${amm.AssetName.username}` : amm.AssetName.name
			}
			if (amm?.Asset2Name?.account) {
				baseAsset = amm.Asset2Name.username ? `~${amm.Asset2Name.username}` : amm.Asset2Name.name
			}

			amm.Market = <Link to={`/account/${amm.Account}`}>
				{baseAsset}
				<XIcon icon="ellipsis" faStyle={'fas'} className={'ml-1 mr-1'}/>
				{quoteAsset}
				</Link>
		}

		// if (!amm?.AssetName && !amm?.Asset2Name) {
		// 	amm.Market = <AccountTag link>{amm.Account}</AccountTag>
		// }

		amm.Balance = Number(amm.Balance) ? <Money value={amm.Balance} drops min={0} max={0}/> : <></>

		amm.Pair = <XBadge className={"text-monospace text-muted"}>{<AMMAssetTag link>{amm.Asset2}</AMMAssetTag>}/{<AMMAssetTag link>{amm.Asset}</AMMAssetTag>}</XBadge>

		if (amm?.TradingFee && amm?.TradingFee > 0) {
			amm.TradingFeePercent = <FormattedNumber value={amm.TradingFee / 1000} minimumFractionDigits={3}></FormattedNumber>
		}

        if (amm?.LPTokenBalance) {
            let {currency, issuer, value } = amm.LPTokenBalance
            if (currency && issuer && value) {
                amm.LPToken = <Money currency={currency} issuer={issuer} value={value} />
            }
        }

        return amm;
	});
	return populatedAMMs;
}

const AMMList = () => {
	const [ ammPools, setAMMPools ] = useState([])
	const [ loading, setLoading ] = useState(false);

	const columns = useMemo(
		() => [
			{
				Header: "Top 1000 AMM pools",
				columns: [
				{
					Header: "#",
					accessor: "idx",
				},
				{
					Header: "Asset pair",
					accessor: "Pair",
					className: "text-right",
				},
				{
					Header: "Market",
					accessor: "Market",
					className: "text-right text-nowrap",
				},
				{
					Header: "AMM Account",
					accessor: "Account",
					Cell: ({ cell: { value }}) => <AccountTag link>{value}</AccountTag>,
					className: "text-left text-monospace",
				},
				{
					Header: "XRP Locked",
					accessor: "Balance",
					className: "text-right text-monospace",
				},
				{
					Header: "Trading fee",
					accessor: "TradingFeePercent",
					Cell: ({ cell: { value }}) => <>{value}%</>,
					className: "text-right text-monospace",
				},
				{
					Header: "LPToken Balance",
					accessor: "LPTokenBalance",
					Cell: ({ cell: { value }}) => <AMMAssetTag link min={0} max={0}>{value}</AMMAssetTag>,
        			className: "text-right text-monospace",
				},
				],
			},
			],
      [],
	)

    /*
    * Fetch latest account balances
    */
    useEffect(() => {
        setLoading(true);
        fetch(`${XRPSCAN_API_URL}/amm/pools`)
        .then(handleResponse)
        .then((data) => {
            setAMMPools(addProperties(data));
        })
        .catch(() => {
            setAMMPools([]);
        })
        .finally(() => {
            setLoading(false);
        });
        return () => {
            setAMMPools([]);
        }
    }, []);

	if (loading) {
		return <Loading/>;
	}

	if (!loading && ammPools && ammPools.length === 0) {
		return (
			<EmptyStateNoRecord title="AMM Pools">
				Automated Market Maker pools will be populated here after the XLS-30 AMM amendment is activated on the mainnet.
			</EmptyStateNoRecord>
		)
	} else {
		return <AdvancedTable
			columns={columns}
			data={ammPools}
			filterProperty='Account'
			filterPlaceholder='Search AMM accounts'
    		defaultPageSizes={[50, 100, 1000, 10000]}
    		initialPageSize={50}
		/>
	}
}

export default AMMList;