import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts/highstock';
import HC_exporting from 'highcharts/modules/exporting';
import HC_accessibility from 'highcharts/modules/accessibility';
import HighchartsReact from 'highcharts-react-official';
import { Card } from 'react-bootstrap';

const highchartsConfig = (serverVersions, validatorCount, dUNLValidatorCount) => {
  const validatorTotal = validatorCount.reduce((a, b) => a + b, 0);
  const dUNLValidatorTotal = dUNLValidatorCount.reduce((a, b) => a + b, 0);
  const validatorThreshold = 0.8;
  return {
    // Chart settings
    chart: {
      zoomType: "xy",
      height: 350,
    },
    title: {
      text: `${validatorTotal} Validators`,
      align: "center",
    },
    subtitle: {
      text: "running on rippled versions",
    },
    tooltip: {
      shared: true,
    },

    // X/Y Axis settings
    xAxis: [{
      categories: serverVersions,
      crosshair: true,
    }],
    yAxis: [{ // Primary yAxis
      title: {
        text: "All validators",
        style: { color: Highcharts.getOptions().colors[0] },
      },
      labels: {
        style: { color: Highcharts.getOptions().colors[0] },
      },
      opposite: true,
      plotBands: [{
        from: validatorTotal * validatorThreshold,
        to: validatorTotal * 2,
        color: new Highcharts.Color(Highcharts.getOptions().colors[2]).setOpacity(0.1).get('rgba'),
      }],
      plotLines: [{
        dashStyle: 'dot',
        width: 2,
        value: validatorTotal * validatorThreshold,
        label: {
            align: 'right',
            style: { fontStyle: 'italic' },
            text: 'Sufficiently updated (80%)',
            x: -10,
        },
      }],
      max: validatorTotal,
      },
      { // Secondary yAxis
      title: {
        text: "UNL Validators",
        style: { color: Highcharts.getOptions().colors[2] },
      },
      labels: {
        style: { color: Highcharts.getOptions().colors[2] },
      },
      max: dUNLValidatorTotal,
    }],

    // Responsive settings
    responsive: {
      rules: [{
        condition: {
          maxWidth: 500
        },
        chartOptions: {
          chart: {
            height: 300
          },
          subtitle: {
            text: null,
          },
          navigator: {
            enabled: false
          }
        }
      }]
    },

    // Data 
    series: [{
      name: "All Validators",
      type: "column",
      yAxis: 0,
      data: validatorCount,
    },{
      name: "UNL Validators",
      type: "spline",
      yAxis: 1,
      data: dUNLValidatorCount,
      marker: { enabled: true },
      dashStyle: "shortdot",
    }]
  }
}

// Convert serverVersions array to key-value with value initialized to 0
const arrayToKeyValue = (arr) => {
  return arr.reduce((a,v) => ({ ...a, [v]: 0}), {})
}

const getValidatorCount = (serverVersions, validators, dUNL=false) => {
  const versions = arrayToKeyValue(serverVersions)
  if (dUNL) {
    validators = validators.filter(v => v.unl && v.unl.length >= 1)
  }
  for (const v of validators) {
    if (v?.server_version?.version in versions) {
      versions[v.server_version.version]++
    } 
  }
  return Object.values(versions);
}

const NetworkChart = (props) => {
  const {
    validators,
    serverVersions,
  } = props;

  const validatorCount = getValidatorCount(serverVersions, validators);
  const dUNLValidatorCount = getValidatorCount(serverVersions, validators, true);

  useEffect(() => {
    HC_exporting(Highcharts);
    HC_accessibility(Highcharts);
  }, [validators]);

  return (
    <Card className='shadow-sm'>
      <Card.Body>
        <HighchartsReact
          highcharts={Highcharts}
          options={highchartsConfig(serverVersions, validatorCount, dUNLValidatorCount)}
        />
      </Card.Body>
    </Card>
  );
}

NetworkChart.propTypes = {
  validators: PropTypes.array,
  serverVersions: PropTypes.array,
};

export default NetworkChart;