import React from 'react';
import PropTypes from 'prop-types';
import Money from './Money';
import AccountTag from './AccountTag';
/*
* MoneyPenny handles IOU and XRP values.
* 
* Usage: 
*
* <MoneyPenny amount={Amount} />
*
*/

const isIOU = (amount) => {
    if (
        amount &&
        typeof amount === "object" &&
        typeof amount.currency === "string" &&
        typeof amount.value === "string" &&
        typeof amount.issuer === "string"
    ) {
        return true;
    } else {
        return false;
    }
}

const issuerInfo = (show, issuer) => {
    if (show) {
        return <span className="ml-0">
                .<AccountTag link>{issuer}</AccountTag>
            </span>;
    } else {
        return <></>;
    }
}

const MoneyPenny = (props) => {
    const { amount, drops, showIssuer } = props;

    if (isIOU(amount)) {
        return( <>
            <Money currency={amount.currency} value={amount.value} issuer={amount.issuer} drops={false}/>
            {issuerInfo(showIssuer, amount.issuer)}
        </>)

    } else if (typeof amount === 'string' && Number(amount)) {
        return <Money value={amount} drops={drops} />
    } else {
        return <></>
    }
}

MoneyPenny.defaultProps = {
    drops: true,
    showIssuer: false,
}

MoneyPenny.propTypes = {
    amount: PropTypes.oneOfType([
    PropTypes.object.isRequired,
    PropTypes.string.isRequired,
]),
    drops: PropTypes.bool,
    showIssuer: PropTypes.bool,
}

export default MoneyPenny;