import React from 'react';
import PropTypes from 'prop-types';
import { LedgerEntryType } from '../../common/Helpers';

const FeeSettings = (props) => {
	const { node } = props;
    if ( node?.LedgerEntryType === LedgerEntryType.FeeSettings ) {
        return (
            <>
                {node?.BaseFee &&
                <tr>
                    <td>BaseFee:</td>
                    <td>
                        {node.BaseFee}
                    </td>
                </tr>
                }
                {node?.BaseFeeDrops &&
                <tr>
                    <td>BaseFeeDrops:</td>
                    <td>
                        {node.BaseFeeDrops}
                    </td>
                </tr>
                }

                {node?.ReferenceFeeUnits >= 0 &&
                <tr>
                    <td>ReferenceFeeUnits:</td>
                    <td>
                        <span>
                            {node.ReferenceFeeUnits}
                        </span>
                    </td>
                </tr>
                }

                {node?.ReserveBase >= 0 &&
                <tr>
                    <td>ReserveBase:</td>
                    <td>
                        <span>
                            {node.ReserveBase}
                        </span>
                    </td>
                </tr>
                }
                {node?.ReserveBaseDrops >= 0 &&
                <tr>
                    <td>ReserveBaseDrops:</td>
                    <td>
                        <span>
                            {node.ReserveBaseDrops}
                        </span>
                    </td>
                </tr>
                }

                {node?.ReserveIncrement >= 0 &&
                <tr>
                    <td>ReserveIncrement:</td>
                    <td>
                        <span>
                            {node.ReserveIncrement}
                        </span>
                    </td>
                </tr>
                }
                {node?.ReserveIncrementDrops >= 0 &&
                <tr>
                    <td>ReserveIncrementDrops:</td>
                    <td>
                        <span>
                            {node.ReserveIncrementDrops}
                        </span>
                    </td>
                </tr>
                }
            </>
        );    
    } else {
        return <></>;
    }
}

FeeSettings.propTypes = {
	node: PropTypes.object,
}

export default FeeSettings;