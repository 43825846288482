import React from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CardGroup from 'react-bootstrap/CardGroup';
import Card from 'react-bootstrap/Card';
import MetricCard from './MetricCard';

const TransactionSummary = (props) => {
  const {
    ledger_index,
    payments_count,
    transaction_count,
    accounts_created,
    ledger_interval,
    tx_per_second,
    xrp_destroyed,
   } = props;

  return (
    <Row>
      <Col xs="12" md="12">
      <Card className="shadow-sm">
        <Card.Body className="metric-card">
        <CardGroup>
          <MetricCard metric={ledger_index} icon="clipboard">Ledger</MetricCard>
          <MetricCard metric={payments_count} icon="money-check">Successful payments</MetricCard>
          <MetricCard metric={transaction_count} icon="file-invoice">Transactions</MetricCard>
          <MetricCard metric={accounts_created} icon="users">Accounts created</MetricCard>
          <MetricCard metric={ledger_interval} unit="sec" icon="stopwatch">Ledger interval</MetricCard>
          <MetricCard metric={tx_per_second} unit="tx/s" icon="tachometer">TPS</MetricCard>
          <MetricCard metric={xrp_destroyed} icon="fire-smoke">XRP Burned <small>(all time)</small></MetricCard>
        </CardGroup>
        </Card.Body>
      </Card>
      </Col>
    </Row>
  );
}

TransactionSummary.propTypes = {
  ledger_index: PropTypes.number,
  payments_count: PropTypes.number,
  transaction_count: PropTypes.number,
  accounts_created: PropTypes.number,
  ledger_interval: PropTypes.number,
  tx_per_second: PropTypes.number,
  xrp_destroyed: PropTypes.number,
};

export default TransactionSummary;
