import React from 'react';
import PropTypes from 'prop-types';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import { FormattedNumber, FormattedDate } from 'react-intl';
import Skeleton from 'react-loading-skeleton';
import XIcon from '../lib/XIcon';

import './TransactionStats.css';

const TransactionStats = (props) => {
	const { stats } = props;

	const getSkeleton = () => {
		let rows = [];
		for (let i = 0; i < 13; i++) {
			rows.push(<li key={i}><dl><dt><Skeleton/></dt><dd><Skeleton/></dd></dl></li>);
		}
		return <div><ul>{rows}</ul></div>;
	}


	// Render this stuff only if the http promise is resolved, else it results in error
	return (
		<div className="TransactionStats">
			<Card>
				<Card.Body>
					<Card.Title>
						<XIcon icon='chart-bar' />
						<span className="pl-2">XRPL stats</span>
				    <span className="float-right"><Link to={`/metrics`}>Metrics &rarr;</Link></span>
					</Card.Title>
					{ Object.keys(stats).length === 0 &&
						getSkeleton()
					}
					{ Object.keys(stats).length > 0 &&
						<div>
							<ul>
								<li>
									<dl>
										<dt className="text-muted"><strong>Date</strong></dt>
										<dd className="text-muted">
											<strong><FormattedDate value={stats?.date} year='numeric' month='long' day='numeric'/></strong>
										</dd>
									</dl>
								</li>
							</ul>
							<ul>
								<li><dl><dt>Payments</dt><dd><FormattedNumber value={stats?.type?.Payment || 0} /></dd></dl></li>
								<li><dl><dt>Accounts created</dt><dd><FormattedNumber value={stats?.metric?.accounts_created || 0} /></dd></dl></li>
								<li><dl><dt>Accounts deleted</dt><dd><FormattedNumber value={stats?.type?.AccountDelete || 0} /></dd></dl></li>
								<li><dl><dt>Active accounts</dt><dd><FormattedNumber value={stats?.metric?.active_accounts || 0} /></dd></dl></li>
							</ul>
							<ul>
								<li><dl><dt>Ledgers closed</dt><dd><FormattedNumber value={stats?.metric?.ledger_count || 0} /></dd></dl></li>
								<li><dl><dt>Transactions</dt><dd><FormattedNumber value={stats?.metric?.transaction_count || 0} /> txs</dd></dl></li>
								<li><dl><dt>Successful txs</dt><dd><FormattedNumber value={stats?.result?.tesSUCCESS || 0} /></dd></dl></li>
								<li><dl><dt>Tx rate</dt><dd><FormattedNumber value={(stats?.metric?.tx_per_ledger / stats?.metric?.ledger_interval) || 0} /> txs/sec</dd></dl></li>
							</ul>
							<ul>
								<li><dl><dt>Account set</dt><dd><FormattedNumber value={stats?.type?.AccountSet || 0} /></dd></dl></li>
								<li><dl><dt>Offers created</dt><dd><FormattedNumber value={stats?.type?.OfferCreate || 0} /></dd></dl></li>
								<li><dl><dt>Escrows finished</dt><dd><FormattedNumber value={stats?.type?.EscrowFinish || 0} /></dd></dl></li>
								<li><dl><dt>Trust set</dt><dd><FormattedNumber value={stats?.type?.TrustSet || 0} /></dd></dl></li>
							</ul>
						</div>
					}
				</Card.Body>
			</Card>
		</div>
	);
}

TransactionStats.defaulProps = {
	stats: {},
}

TransactionStats.propTypes = {
	stats: PropTypes.object.isRequired,
};

export default TransactionStats;