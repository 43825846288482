import React from 'react';
import PropTypes from 'prop-types';
import Table from 'react-bootstrap/Table';
import Badge from 'react-bootstrap/Badge';
import Money from '../lib/Money';
import { currencyName } from '../common/Helpers';


const AccountOrder = (props) => {
	const { orders } = props;

	return (
		<div>
			<Table responsive>
				<thead>
					<tr>
						<th className="text-center">#</th>
						<th className='text-center'>Type</th>
						<th className='text-right'>Quantity</th>
						<th className='text-right'>Offer</th>
						<th className='text-right'>Price</th>
					</tr>
				</thead>
				<tbody>
					{ orders.map((order, index) => 
					<tr key={index}>
						<td className="text-center">{index+1}</td>
						<td className="text-center">
							{	order.specification.direction === "buy" && <Badge variant="success">BUY&nbsp;</Badge> }
							{	order.specification.direction === "sell" && <Badge variant="warning">SELL</Badge> }
						</td>
						<td className={`text-right ${order.specification.direction === "buy" ? "table-success" : "table-warning"}`}>
							<Money
								value={order.specification.quantity.value}
								currency={order.specification.quantity.currency}
								issuer={order.specification.quantity.counterparty}
							/>
						</td>
						<td className={`text-right ${order.specification.direction === "buy" ? "table-warning" : "table-success"}`}>
							<Money
								value={order.specification.totalPrice.value}
								currency={order.specification.totalPrice.currency}
								issuer={order.specification.totalPrice.counterparty}
							/>
						</td>
						<td className='text-right'>
							<Money
								value={order.properties.makerExchangeRate}
								currency={`${currencyName(order?.specification?.totalPrice?.currency)}/${currencyName(order?.specification?.quantity?.currency)}`}
							/>
						</td>
					</tr>
					)}
				</tbody>
			</Table>
		</div>
	);
}

AccountOrder.propTypes = {
	orders: PropTypes.array.isRequired,
	account: PropTypes.object.isRequired,
}

export default AccountOrder;